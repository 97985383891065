// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tab_tab__Z2EY2 {\n  width: var(--width-tab);\n  height: var(--height-tab);\n  -webkit-transform: skewX(-30deg);\n          transform: skewX(-30deg);\n  background: rgb(59, 47, 91);\n  border-top-right-radius: 70px;\n  border-top-left-radius: 20px;\n  cursor: pointer;\n  margin-right: 10px;\n}\n.Tab_tab__Z2EY2 div {\n  -webkit-transform: skewX(30deg);\n          transform: skewX(30deg);\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  align-items: center;\n}\n\n.Tab_notSelected__26txx {\n  height: calc(var(--height-tab) - 5px);\n}", "",{"version":3,"sources":["webpack://src/components/Tab/Tab.module.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,yBAAA;EACA,gCAAA;UAAA,wBAAA;EACA,2BAAA;EACA,6BAAA;EACA,4BAAA;EACA,eAAA;EACA,kBAAA;AACJ;AAAI;EACI,+BAAA;UAAA,uBAAA;EACA,aAAA;EACA,YAAA;EACA,uBAAA;EACA,mBAAA;AAER;;AAIA;EACI,qCAAA;AADJ","sourcesContent":[".tab{\n    width: var(--width-tab);\n    height: var(--height-tab);\n    transform : skewX(-30deg);\n    background: rgb(59, 47, 91);\n    border-top-right-radius:70px;\n    border-top-left-radius:20px;\n    cursor: pointer;\n    margin-right: 10px;\n    div{\n        transform : skewX(30deg);\n        display: flex;\n        height:100%;\n        justify-content: center;\n        align-items: center;\n\n    }\n}\n\n\n.notSelected{\n    height: calc(var(--height-tab) - 5px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tab": "Tab_tab__Z2EY2",
	"notSelected": "Tab_notSelected__26txx"
};
export default ___CSS_LOADER_EXPORT___;
