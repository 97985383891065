import {useGetLoginInfo} from '@multiversx/sdk-dapp/hooks/account';
import {useGetAccountInfo} from '@multiversx/sdk-dapp/hooks';
import {logout} from '@multiversx/sdk-dapp/utils';

import { useEffect,useState,createContext } from "react";
import axios from 'axios';


export const Context = createContext();

export default function AuthProvider(props) {

    const [jwtToken,setJwtToken] = useState();
    

    const {isLoggedIn,tokenLogin}=useGetLoginInfo();

    const {address}=useGetAccountInfo();


    useEffect(()=>{
        try{
            
            
            if(isLoggedIn && tokenLogin){
                
                const token = localStorage.getItem('token');
                if(token===undefined || token===null ){
                    
                    if(tokenLogin!==null && tokenLogin.signature!==undefined){
                        
                        axios.post(`${process.env.REACT_APP_API_URL}/login`,{
                            address: address,
                            token: tokenLogin.loginToken,
                            signature:tokenLogin.signature
                        })
                        .then(function(response){
                            
                            //window.alert("connect")
                            localStorage.setItem('token',response.data.token);
                            setJwtToken(response.data.token)

                        }).catch((error)=>{
                            if (error.response) {
                                
                                
                                window.alert(JSON.stringify(error))
                                logout()
                            }else{
                                //window.alert(error)
                            }
                            //logout()
                        })
                    }else{
                        logout() 
                    }
                }else{
                    
                    axios.get(
                        `${process.env.REACT_APP_API_URL}/check_token`,
                        {headers: { Authorization: `Bearer ${token}` }}
                    ).then(function(response){
                        
                        //window.alert(response.data.token)
                        localStorage.setItem('token',response.data.token);
                        setJwtToken(response.data.token);
                    }).catch(()=>{
                        
                        
                        localStorage.removeItem('token');
                        setJwtToken(undefined)
                        logout()
                    })
                } 
            
            }else{
                
                const token = localStorage.getItem('token');
                if(token!==undefined && token!==null ){
                    
                    localStorage.removeItem('token');
                    setJwtToken(undefined)
                }
            }
        }catch(e){
            
            logout()
        }
    },[isLoggedIn,tokenLogin]);

    return(
        <Context.Provider value={{jwtToken }}>
            {props.children}
        </Context.Provider>
    )
}