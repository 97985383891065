import Card from "../Card"
import styles from "./CardAntsGame.module.scss"
/* import stonedImg from "./assets/img/stoned-egod.png"
import elrondMinersImg from "./assets/img/elrond-miners.png"
import antsClubImg from "./assets/img/ants-club.png" */

import "react-responsive-carousel/lib/styles/carousel.min.css";
import InfiniteCarousel from 'react-leaf-carousel';
import {useEffect,useState} from "react"
import axios from "axios"

const CardAntsGame = (props) => {
    const [projects,setProjects] = useState([])

    useEffect(()=>{
        async function getProjects(){
            let response = await axios.get(process.env.REACT_APP_API_URL+"/get_projects")
            setProjects(response.data)
        }
        getProjects()
    },[])
    return(
        <Card>
            <h2 className={styles.title}>The Ants Game</h2>
            <h3>The playable collections</h3>
            <div className={styles.carousel}>

            {projects.length!==0 && <InfiniteCarousel
                lazyLoad={false}
                breakpoints={[
                    {
                        breakpoint: 500,
                        settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        },
                    },
                    {
                        breakpoint: 768,
                        settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        },
                    },
                ]}
                dots={false}
                showSides={false}
                sidesOpacity={0.5}
                sideSize={0.1}
                slidesToScroll={4}
                slidesToShow={4}
                scrollOnDevice={true}
            >
                {
                    projects.map((project)=>
                        <div key={project.id} className={project.comingSoon ? styles.comingSoon : styles.gameSelection} onClick={()=>{!project.comingSoon && props.onClick(project.id)}}>
                            <img alt={project.name} src={`${process.env.REACT_APP_API_URL}/${project.name}/carousel.png`} />
                            {/* {project.comingSoon && <div>Coming Soon</div>} */}
                        </div>
                    )
                }

                    {/* <div className={styles.gameSelection} onClick={()=>{props.onClick(8)}}>
                        <img alt="" src={jcorp} />
                        <div>Enter in the contest</div>
                    </div>
                    <div className={styles.comingSoon} onClick={()=>{}}>
                        <img alt="" src={stonedegod} />
                        <div>Coming Soon</div>
                    </div>
                    <div className={styles.comingSoon}>
                        <img src={angryP} alt="" />
                        <div>Coming Soon</div>
                    </div>
                    <div className={styles.comingSoon}>
                        <img alt="" src={ants} />
                        <div>Coming Soon</div>
                    </div>
                    <div className={styles.comingSoon}>
                        <img alt="" src={bolts} />
                        <div>Coming Soon</div>
                    </div>
                    <div className={styles.comingSoon}>
                        <img alt="" src={miners} />
                        <div>Coming Soon</div>
                    </div>
                    <div  className={styles.comingSoon}>
                        <img alt="" src={pandans} />
                        <div>Coming Soon</div>
                    </div>
                    <div  className={styles.comingSoon}>
                        <img alt="" src={roosters} />
                        <div>Coming Soon</div>
                    </div>

                    <div className={styles.comingSoon}>
                        <img alt="" src={synth} />
                        <div>Coming Soon</div>
                    </div>
                    <div className={styles.comingSoon}>
                        <img alt="" src={xgang} />
                        <div>Coming Soon</div>
                    </div> */}

            </InfiniteCarousel>}
                
                {/* <div onClick={props.onClickStoned} className={styles.partnerProject}><img alt="stoned" src={stonedImg}/></div>
                <div className={styles.antsGameSection}>
                    <h3>The playable collections</h3>
                    <img alt="ants" src={antsClubImg}/>
                    <div>Ants</div>
                </div>
                <div  onClick={props.onClickMiners} className={styles.partnerProject}><img alt="miners" src={elrondMinersImg}/></div> */}
            </div>

        </Card>
    )
}

export default CardAntsGame;