// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContestRule_contestRule__gL-xG {\n  background-color: #000000;\n  padding: 20px;\n  color: white;\n  position: absolute;\n  z-index: 4;\n  height: 100%;\n  overflow: scroll;\n  font-family: \"Roboto\";\n  text-align: left;\n  border-radius: 10px;\n}\n.ContestRule_contestRule__gL-xG h2 {\n  font: 36px;\n  text-align: left;\n}\n.ContestRule_contestRule__gL-xG h3 {\n  text-align: left;\n  font-style: none;\n  text-decoration: underline;\n  margin-bottom: 0;\n}\n.ContestRule_contestRule__gL-xG p {\n  margin-top: 0;\n}\n.ContestRule_contestRule__gL-xG .ContestRule_button__Qx3kq {\n  left: 50%;\n  -webkit-transform: translateX(-50%);\n          transform: translateX(-50%);\n  position: relative;\n}\n.ContestRule_contestRule__gL-xG .ContestRule_link__3nakH {\n  cursor: pointer;\n  color: #2A30C1;\n}", "",{"version":3,"sources":["webpack://src/components/ContestRule/ContestRule.module.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,aAAA;EACA,YAAA;EACA,kBAAA;EACA,UAAA;EACA,YAAA;EACA,gBAAA;EACA,qBAAA;EACA,gBAAA;EACA,mBAAA;AACJ;AAAI;EACI,UAAA;EACA,gBAAA;AAER;AAAI;EACI,gBAAA;EACA,gBAAA;EACA,0BAAA;EACA,gBAAA;AAER;AAAI;EACI,aAAA;AAER;AAAI;EACI,SAAA;EACA,mCAAA;UAAA,2BAAA;EACA,kBAAA;AAER;AAAI;EACI,eAAA;EACA,cAAA;AAER","sourcesContent":[".contestRule{\n    background-color: #000000;\n    padding: 20px;\n    color: white;\n    position: absolute;\n    z-index: 4;\n    height: 100%;\n    overflow: scroll;\n    font-family: \"Roboto\";\n    text-align: left;\n    border-radius: 10px;\n    h2{\n        font:36px;\n        text-align: left;\n    }\n    h3{\n        text-align: left;\n        font-style: none;\n        text-decoration: underline;\n        margin-bottom: 0;\n    }\n    p{\n        margin-top: 0;\n    }\n    .button{\n        left:50%;\n        transform: translateX(-50%);\n        position: relative;\n    }\n    .link{\n        cursor: pointer;\n        color:#2A30C1;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contestRule": "ContestRule_contestRule__gL-xG",
	"button": "ContestRule_button__Qx3kq",
	"link": "ContestRule_link__3nakH"
};
export default ___CSS_LOADER_EXPORT___;
