import styles from "./PopupInfoAdditionalPoint.module.scss"


const PopupInfoAdditionalPoint = ({onClose}) => {

    
    return(
        <>
            
            <div className="bg" onClick={onClose}></div>
            <div className={styles.popup}>
                <p>
                    If you manage to have all the requested traits in a series, you are awarded 5 bonus points.<br/>
                    Some bonus points are hidden behind certain series. The lower you are in the ranking, the higher the probability factor to get a bonus. 
                </p>    
            </div>
        </>
    )
}

export default PopupInfoAdditionalPoint;