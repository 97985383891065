import {useState,useEffect} from "react"
import styles from "./Account.module.scss"
import Card from "../Card"
import egldImg from "./assets/img/token-egld.png"
import antsGameImg from "./assets/img/token-ants-game.png"
import antsClubImg from "./assets/img/token-ants-club.png"
import antsImg from "./assets/img/ants.png"
import gearImg from "../../assets/img/gear.png"
import PopupFeaturesProfile from "../PopupFeaturesProfile"
import PopupTokenAnts from "../PopupTokenAnts"
import {useGetAccountInfo} from '@multiversx/sdk-dapp/hooks/account';
import BigInt from "big-integer"
import axios from "axios"
import ProfilePicture from "../ProfilePicture"


const Account = (props) => {

    const [showFeatures, setShowFeatures] = useState(false)

    const [qteToken, setQteToken] = useState({})

    const { address,account } = useGetAccountInfo();

    const [tokenChoosen, setTokenChoosen] = useState("club")

    const [showPopupToken, setShowPopupToken] = useState(false)

    const [categoryFeatures,setCategoryFeatures] = useState(undefined)

    const [updateProfilePicture,setUpdateProfilePicture] = useState(false)


    useEffect(()=>{
        const fetchToken = async () => {
            let response = await axios.get(`https://api.elrond.com/accounts/${address}/tokens?identifiers=${process.env.REACT_APP_TOKEN_ANTS_CLUB}%2C${process.env.REACT_APP_TOKEN_ANTS_GAME}`)
            let newObjectQteToken={}
            response.data.map((data)=>
                newObjectQteToken[data.identifier]=parseInt(data.balance,10)
            )
            setQteToken(newObjectQteToken)
        }

        fetchToken()
    },[address])

    const update = () => {
        setShowFeatures(false)
        setUpdateProfilePicture(!updateProfilePicture)
    }
    return(
        <>
            {showFeatures && <PopupFeaturesProfile  updateProfilePicture={update} category={categoryFeatures} user={props.user} facebook={props.facebook} discord={props.discord} twitter={props.twitter} mail={props.mail} setUser={props.setUser} setFacebook={props.setFacebook} setDiscord={props.setDiscord} setTwitter={props.setTwitter} setMail={props.setMail} onClose={()=>setShowFeatures(false)}/>}
            {showPopupToken && <PopupTokenAnts token={tokenChoosen} onClose={()=>setShowPopupToken(false)}/>}
            <div className={styles.account}>
                <Card className={styles.card80}>
                    <h2>User Account</h2>
                    <div>
                        <img src={antsImg} alt="ants"/>
                        <div>
                            <div>
                                <h2>User Name</h2>
                                <p>{props.user}</p>
                            </div>
                            <img onClick={()=>setShowFeatures(true)} src={gearImg} alt="gear"/>
                            
                        </div>
                        <div>
                            <h2>Your wallet address:</h2>
                            <p>{address}</p>
                        </div>
                        
                    </div>
                    <ProfilePicture showMobileInventory={props.showMobileInventory} updateProfilePicture={updateProfilePicture} address={address} setShowInventory={setShowFeatures} setCategoryFeatures={setCategoryFeatures}/>
                    <div className={styles.balance}>
                        <div><img alt="EGLD" src={egldImg}/><p>{!isNaN(account.balance) ? Math.round(BigInt(account.balance)/BigInt(10**18)*100)/100 : 0} EGLD</p></div>
                        <div><img onClick={()=>{setTokenChoosen("club");setShowPopupToken(true)}} alt="Ants Club" src={antsClubImg}/><p>{qteToken[process.env.REACT_APP_TOKEN_ANTS_CLUB] ?? 0} ANTSCLUB</p></div>
                        <div><img onClick={()=>{setTokenChoosen("game");setShowPopupToken(true)}} alt="Ants Game" src={antsGameImg}/><p>{qteToken[process.env.REACT_APP_TOKEN_ANTS_GAME] ?? 0} ANTSGAME</p></div>
                    </div>
                </Card>
                <Card className={styles.card20}>
                    <h2>Your Wallet Balance:</h2>
                    <div>
                        <div><img alt="EGLD" src={egldImg}/><p>{!isNaN(account.balance) ? Math.round(BigInt(account.balance)/BigInt(10**18)*100)/100 : 0} EGLD</p></div>
                        <div><img onClick={()=>{setTokenChoosen("club");setShowPopupToken(true)}} alt="Ants Club" src={antsClubImg}/><p>{qteToken[process.env.REACT_APP_TOKEN_ANTS_CLUB] ?? 0} ANTSCLUB</p></div>
                        <div><img onClick={()=>{setTokenChoosen("game");setShowPopupToken(true)}} alt="Ants Game" src={antsGameImg}/><p>{qteToken[process.env.REACT_APP_TOKEN_ANTS_GAME] ?? 0} ANTSGAME</p></div>
                    </div>
                </Card>
            </div>
        </>
    )
}

export default Account;