import styles from "./Tab.module.scss";

function Tab(props){

    return(
        <div onClick={props.onClick} className={!props.selected ? styles.notSelected + " "+ styles.tab : styles.tab}>
            <div>{props.children}</div>
        </div>
    )
}

export default Tab;