import styles from "./Card.module.scss"

const Card = (props) => {
    return(
        <div className={styles.card + (props.className!==undefined ? (" " + props.className):"") + (props.isMobile ? ` ${styles.isMobile}` :"")} >
            {props.isMobile}
            {props.background !== undefined && <div className={styles.bg}><img alt="" className={styles.background} src={props.background} /></div>}

            {props.children}
        </div>
    )
}

export default Card;