import {useEffect,useState} from 'react'
import styles from "./ScoreBoard.module.scss"
import Button from "../ButtonDesigned";
import axios from "axios"
import background from "../../assets/img/tresory-background.png"
import arrowImg from "../../assets/img/arrow-left.svg"


const PopupDisclaimer = ({onClose,idProjet,address,title,notPopup}) => {

    const [scores, setScores] = useState([])

    const fetchData = async () => {
        let response = await axios.get(process.env.REACT_APP_API_URL+"/get_score_contest?projectId="+idProjet)
        setScores(response.data)
    }
    useEffect(()=>{
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <>
            {!notPopup && <div className="bg" onClick={onClose}></div>}
            <div className={styles.popup + " " +(notPopup && styles.notPopup)}>
                <img src={background} className={styles.background} alt="" />
                <img alt="" className={styles.arrow} src={arrowImg} onClick={(onClose)}/>

                <Button className={styles.button}>
                    {title ? title : "Score"}
                </Button>

                <table className={styles.table}>
                    <thead>
                        <tr>
                            <td><div>Ranking</div></td>
                            <td><div>Players</div></td>
                            <td><div>Points</div></td>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {
                            scores.map((score,index)=>
                                <tr id={score.id}>
                                    <td>{index + 1}{index===1 ? "st" : (index===2 ? "nd" : (index===3 ? "rd" : "th"))}</td>
                                    <td className={address===score.address && styles.green}><a href={`https://${process.env.ENVIRONMENT === "development" ? "devnet-" : ""}explorer.multiversx.com/accounts/${score.address}`}><img className={styles.profile} src={`${process.env.REACT_APP_API_URL}/profile_pictures/${score.address}.png`} alt=""/>{score.userName!==undefined ? score.userName : (score.address.substring(0, 5) + "..." + score.address.substr(score.address.length - 4))}</a></td>
                                    <td>{score.pointNumber}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default PopupDisclaimer;