import LoginPopup from "../components/Login"
import bgImg from "../assets/img/bg-login.png"
import {useGetLoginInfo} from '@multiversx/sdk-dapp/hooks/account';

function Login(){
    const {isLoggedIn} = useGetLoginInfo()


    return(
        <>
        {!isLoggedIn && <div className="login-page">
            <img className="login-page-bg" alt="bg" src={bgImg}/>
            <LoginPopup/>
        </div>}
        </>
    )
}

export default Login;