import styles from "./Input.module.scss"

const PopupFeaturesProfile = (props) => {
    return(
        <div className={styles.input}>
            <div><p>{props.label}</p></div>
            <div>
                <input onChange={props.onChange} value={props.value}/>
            </div>
        </div>
    )
}

export default PopupFeaturesProfile;