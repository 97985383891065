import Home from "./pages/Home"
import Dao from "./pages/Dao"
import Login from "./pages/Login"
import Admin from "./pages/Admin"
import AdminProjects from "./pages/AdminProjects"
import AdminKeyElement from "./pages/AdminKeyElement"


const routes = [
    {
        key:1,
        component:<Home/>,
        path:"/",
        name:"Home",
        authenticatedRoute: true,
        showInTab: true
    },
    {
        key:2,
        component:<Dao/>,
        path:"/dao",
        name:"Dao",
        authenticatedRoute: true,
        showInTab: true
    },
    {
        key:3,
        component:<Login/>,
        path:"/login",
        authenticatedRoute: false,
        showInTab: false
    },
    {
        key:4,
        component:<Home/>,
        path:"/home",
        name:"Home",
        authenticatedRoute: true,
        showInTab: false
    },
    {
        key:5,
        component:<Admin/>,
        path:"/admin",
        authenticatedRoute: true,
        showInTab: false
    },
    {
        key:5,
        component:<AdminProjects/>,
        path:"/admin/:id",
        authenticatedRoute: true,
        showInTab: false
    },
    {
        key:6,
        component:<AdminKeyElement/>,
        path:"/admin/:id/:type", //:type can be "joker" or "key"
        authenticatedRoute: true,
        showInTab: false
    }
]

export default routes;