import { useState } from "react"
import styles from "./Pack.module.scss"
//import pack1Img from "./assets/img/pack-1-img.png"
//import pack2Img from "./assets/img/pack-2-img.png"
import pack1Img from "./assets/img/pack-1-OG.png"
import pack2Img from "./assets/img/pack-2-OG.png"
import Command from "../Command"

const Pack = (props) => {
    const [showPopup, setShowPopup] = useState(false)

/*     const {
        callbackRoute,
        transactions,
        error,
        onAbort,
        hasTransactions
      } = useSignTransactions(); */

    return(
        <>
            {showPopup && (props.inactive === undefined || !props.inactive) && <Command number={props.pack} soldOut={props.soldOut} price={props.price} nbAntsClub={props.nbAntsClub} nbAntsGame={props.nbAntsGame} maxToMint={props.maxToMint} statut={props.statut} onClose={()=>setShowPopup(false)} title={"Pack "+props.pack}/>}
            <div className={styles.pack} onClick={(props.inactive === undefined || !props.inactive) ? ()=>setShowPopup(true) : undefined}>
                {/*<ButtonDesigned>Pack {props.pack}</ButtonDesigned>*/}
                {/*<img alt="pack" src={props.pack===1 ? pack1Img : pack2Img}/>*/}
                {/*<ButtonDesigned className={styles.ogDesigned}>Pack {props.pack}</ButtonDesigned>*/}
                <img  alt="pack" src={props.pack===1 ? pack1Img : pack2Img}/>
                <div className={styles.price}>{props.price && props.price.toFixed(2)} EGLD</div>
            </div>
        </>
    )
}

export default Pack;