import styles from "./GameTool.module.scss"
import Button from "../ButtonDesigned"
import frameIt from "../../assets/img/frame-it.svg"
import {useEffect,useState} from "react"
import axios from "axios"
import arrowImg from "../../assets/img/arrow-left.svg"
import { useGetNetworkConfig, useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';


function base64ToUtf8(base64Str) {
    // Utilisez la méthode `atob()` pour décoder la chaîne base64 en une chaîne binaire
    const binaryStr = atob(base64Str);
    // Créez un tableau de codes UTF-8 à partir de la chaîne binaire
    const utf8Codes = new Uint8Array(binaryStr.length);
    for (let i = 0; i < binaryStr.length; i++) {
      utf8Codes[i] = binaryStr.charCodeAt(i);
    }
    // Utilisez la méthode `TextDecoder` pour décoder les codes UTF-8 en une chaîne UTF-8
    const utf8Decoder = new TextDecoder();
    const utf8Str = utf8Decoder.decode(utf8Codes);
    return utf8Str;
}

const GameTool = ({idProject,key,onClose,contest,openPremium}) => {
    const [selectedAttribute,setSelectedAttribute] = useState()
    const [collectionTotalTraitNumber,setCollectionTotalTraitNumber] = useState(0)
    const [collectionId,setCollectionId] = useState()
    const [holderNb,setHolderNb] = useState(0)
    const [collectionTotalNumber,setCollectionTotalNumber] = useState(0)

    const [dataTrait,setDataTrait] = useState({})
    const { address } = useGetAccountInfo();
    const { network } = useGetNetworkConfig();


    const fetchData = async () => {
        try{
            let response = await axios.get(`${process.env.REACT_APP_API_URL}/get_traits?idProjet=${idProject}`)
            console.log(response)
            //setCollectionTotalTraitNumber(response.data[0].gameCollections[0].totalTraitNumber!==null ? response.data[0].gameCollections[0].totalTraitNumber : 0)
            setCollectionTotalNumber(response.data[0].gameCollections[0].totalNumber!==null ? response.data[0].gameCollections[0].totalNumber : 0)
            let idCollection = response.data[0].gameCollections[0].idCollection
            setCollectionId(idCollection)
            let data = {}
            let traitNum = 0
            let nftInWallet = await axios.get(`${network.apiAddress}/accounts/${address}/nfts?size=10000&collections=${idCollection}`)
            for(const trait of response.data[0].gameCollections[0].gameTraits){
                try{
                    if(Object.keys(data).length===0) setSelectedAttribute(trait.traitName) 
                    data[trait.traitName] = {totalNumber : trait.totalNumber,value:[]}
                    for(const traitValue of trait.gameTraitValues){
                        traitNum++
        
                        //Check if attribute in wallet
                        let d = {value:traitValue.value,totalNumber:traitValue.totalNumber,score:traitValue.score}
                        for(const nft of nftInWallet.data){
                            if(response.data[0].gameCollections[0].attributeOnChain){
                                const attributesUTF8 = base64ToUtf8(nft.attributes).split(";");
                                for (const attributes of attributesUTF8){
                                    let traitNft = attributes.split(":")
                                    let traitName = traitNft[0]
                                    let traitValue = traitNft[1]
                                    if(trait.traitName === traitName && d.value === traitValue){
                                        d.own = true 
                                    }
                                }
                            }else{
                                
                                for(const attribute of nft.metadata.attributes){
                                    if(trait.traitName === attribute.trait_type && d.value === attribute.value){
                                        d.own = true
                                    }
                                }
                            }
                        }
                        
                        data[trait.traitName].value.push(d)
                    }
                }catch(e){
                    console.log(e)
                }
                
            }
            setCollectionTotalTraitNumber(traitNum)
            setDataTrait(data)
    
            let apiRequest=`https://${process.env.ENVIRONMENT === "development" ? "devnet-" : ""}api.elrond.com/collections/${idCollection}/accounts?size=10000`
            response = await axios.get(apiRequest);
            let addressOwner = []
            response.data.forEach((address)=>{
                if(!addressOwner.includes(address.address)){
                    addressOwner.push(address.address)
                }
            })
            setHolderNb(addressOwner.length)
        }catch(e){
            console.log(e)
        }
        

        
    }

    useEffect(()=>{
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const openPremiumTool=()=>{
        onClose()
        openPremium()
    }

    return(
        <>
            <img alt="" className={styles.arrow} src={arrowImg} onClick={onClose}/>

            <h2>Stats</h2>
            <div className={styles.tool}>
                
                <div>
                    <div className={styles.generalStats}>
                        <div>
                            <div>Holder</div>
                            <Button className={styles.button}>{holderNb}</Button>
                        </div>
                        <div style={{alignSelf: "end"}} >
                            <Button style={{cursor:"pointer",color:"yellow"}} className={styles.button} onClick={openPremiumTool}>Premium Tools</Button>
                        </div>
                        <div>
                            <div>Attributes</div>
                            <Button className={styles.button}>{collectionTotalTraitNumber}</Button>
                        </div>
                    </div>
                    <div className={styles.table}>
                        <table>
                            <thead>
                                <tr>
                                    <th><Button className={styles.button3}>{selectedAttribute}</Button></th>
                                    <th>Quantity</th>
                                    <th>Rarity</th>
                                    {contest && <th>Score</th>}
                                    <th>Marketplace</th>
                                    <th>In my wallet ?</th>
                                    {/* <th>Is key ?</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataTrait[selectedAttribute] !== undefined && dataTrait[selectedAttribute].value.map((value)=>
                                    <tr>
                                        <td>{value.value}</td>
                                        <td>{value.totalNumber}</td>
                                        <td>{Math.floor(value.totalNumber/collectionTotalNumber*10000)/100} %</td>
                                        {contest && <td>{value.score}</td>}
                                        <td><a rel="noreferrer" href={`https://www.frameit.gg/marketplace/${collectionId}/items?&activeFilters=[{"trait":"${selectedAttribute}","attribute":"${value.value}"}]`} target="_blank"><img alt="" src={frameIt}/></a></td>
                                        <td className={styles.green}>{value.own && "Yes"}</td>
                                        {/* <td>Yes</td> */}
                                    </tr> 
                                    )
                                }
                                
                            </tbody>
                        </table>
                    </div>
                    <div className={styles.specificTrait}>
                        {
                            Object.keys(dataTrait).map((key) =>
                                <div>
                                    <Button onClick={()=>setSelectedAttribute(key)} className={styles.button2}>{key}</Button>
                                    <div className={styles.green}>{dataTrait[key].value.length}</div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default GameTool;