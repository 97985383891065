import CardAntsGame from "../components/CardAntsGame"
import CardMint from "../components/CardMint"
import GameModeSelector from "../components/GameModeSelector"
import { useNavigate } from 'react-router-dom';

//import Login from './Login'
//import { useGetLoginInfo } from '@multiversx/sdk-dapp/hooks/account';



function Home({showGame,setShowGame}){
    const navigate = useNavigate()

    return(
        <>  
            <div className="home-page">
                {showGame !== undefined ? (
                    <GameModeSelector onClose={()=>{setShowGame(undefined);navigate("/")}} id={showGame}/>
                ):(
                    <CardAntsGame onClick={setShowGame}/>
                )}

                {showGame === undefined && <CardMint/>}

            </div>
        </>
    )


}
export default Home