import styles from "./PopupDao.module.scss"
import detailImg from "./assets/img/detail.png"
import antsImg from "../../assets/img/ants.png"



const PopupDao = (props) => {
    return(
        <>
            <div className="bg" onClick={props.onClose}></div>
            <div className={styles.popupDao}>
                <h2>Distribution of profits</h2>
                <img src={antsImg} alt="ants"/>
                <img src={detailImg} alt="detail" />
            </div>
        </>
    )
}

export default PopupDao;