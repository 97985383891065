import frameImg from "../assets/img/frame.png";
import {useState} from 'react'
const ImagesFrame = ({onClick,key,name,url,isMobile}) => {
    const [imgError, setImgError] = useState(false);

    const handleImgError = () => {
        if (!imgError) {
            setImgError(true);
        }
    };

    return(
        <>
        {   !imgError &&
            <>
            {
                isMobile ?
                    <div style={{cursor:"pointer"}} onClick={onClick} key={key}>
                        <h3 style={{position: "absolute",color: "white",zIndex: "11",top: "0px",padding: "0",margin: 0,left: "50%",transform:"translateX(-50%)"}} >{name}</h3>

                        <img style={{position: "absolute",height: "300px",width: "auto",left: "50%",top: "11px",transform: "translateX(-50%)",zIndex: "10"}} src={frameImg} alt="frame" />
                        
                        <img onError={handleImgError} style={{position: "absolute",height: "243px",width: "auto",left: "50%",top: "35px",transform: "translateX(-50%)"}} src={url}  alt={name} />
                    </div>
                :
                    <div style={{cursor:"pointer"}} onClick={onClick} key={key}>
                        <h3>{name}</h3>

                        <div>
                            <img src={frameImg} alt="frame" />
                            
                            <img onError={handleImgError} src={url}  alt={name} />
                        </div>
                    </div>
            }
            </>
        }
        </>
    )
}

export default ImagesFrame;