import { useEffect,useState } from "react";
import styles from "./PopupContestValidation.module.scss"
import Button from "../ButtonDesigned";


const PopupContestValidation = ({onClose,position,traitKey,maxToClaim,claimNb,onValidation}) => {

    const [totalPoint,setTotalPoint] = useState(0)
    const [totalPotentialPoint,setTotalPotentialPoint] = useState(0)

    useEffect(()=>{
        let score=0
        let nb=0
        let totalPotential=0
        for(const trait of traitKey){
            totalPotential += trait.pointNumber
            if(trait.own || trait.replaced){
                score += trait.pointNumber
                nb ++
            }
        }
        totalPotential += 5
        if(nb === traitKey.length){
            score+=5
        }
        setTotalPotentialPoint(totalPotential)
        setTotalPoint(score)
    },[traitKey])

    const validation = () => {
        onClose()
        onValidation()
    }
    return(
        <>
            <div className="bg" onClick={onClose}></div>
            <div className={styles.popup}>
                {
                    maxToClaim > claimNb ? <>
                        <p className={styles.title}>Congratulations</p>
                        {position && <p >You started the day at the {position}{position===1 ? "st" : (position===2 ? "nd" : (position===3 ? "rd" : "th"))} position</p> }
                        <p className={styles.information}>
                            You can only claim points for {maxToClaim} differents rounds per day now. 
                            <br/>So choose wisely
                        </p>
                        <p >You can win <span className={styles.totalPoint}>{totalPoint}</span> out of {totalPotentialPoint} points</p>
                        <p >Claim</p>
                        <div className={styles.buttons}>
                            <Button onClick={validation}>Yes</Button>
                            <Button onClick={onClose}>No</Button>
                        </div>
                        <div className={styles.quantity}>{claimNb + 1}/{maxToClaim}</div>
                    </>
                    : <>
                        <p className={styles.title}>Too much claim</p>
                        {position && <p >You started the day at the {position}{position===1 ? "st" : (position===2 ? "nd" : (position===3 ? "rd" : "th"))} position</p> }
                        <p className={styles.information}>
                            You could only claim points for {maxToClaim} different rounds per day now. 
                            <br/>So choose wisely
                        </p>
                        <div className={styles.quantity}>{claimNb + 1}/{maxToClaim}</div>
                    </>
                }
                
            </div>
        </>
    )
}

export default PopupContestValidation;