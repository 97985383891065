import styles from "./Admin.module.scss"
import Card from "../Card"
import { useEffect,useContext,useState } from "react"
import {Context} from '../../ContextProvider'
import axios from "axios"
import Button from "../Button"
import { useNavigate } from 'react-router-dom';

const Admin = ({isAdmin}) => {
    const { jwtToken } = useContext(Context);

    const navigate = useNavigate();
    const [projects,setProjects] = useState()
    const [showAddProject,setShowAddProject] = useState(false)
    const [nameNewProject,setNameNewProject] = useState()


    useEffect(()=>{
        const getProjects = async() => {
            try{
                let response = await axios.get(process.env.REACT_APP_API_URL+"/get_projects")
                setProjects(response.data)
            }catch(e){
                console.log(e)
            }
        }
        getProjects()
    },[])
    
    const createNewProject=()=>{
        setShowAddProject(false)
        axios.post(process.env.REACT_APP_API_URL+"/create_new_project",
        {name:nameNewProject},
        {headers: { Authorization: `Bearer ${jwtToken}` }})
        .then((response)=>{
            window.alert("Added")
            let projectsState = [...projects]
            projectsState.push(response.data)
            setProjects(projectsState)
        })
        .catch((error)=>{
            window.alert(error);
        })
        setNameNewProject(undefined)
    }
    return(            
        <Card>
            <div className={styles.admin}>
                <div>
                    <h2>Admin</h2>
                    <div className={styles.listProjects}>
                       {!isAdmin ?
                            <p>Sorry, you are not admin</p>
                        :
                            <div>
                                {
                                    projects && projects.map((project)=>
                                        <p onClick={()=>navigate(`${/admin/}${project.id}`)} className={styles.projects}>{project.name}</p>
                                    )
                                }
                            </div>
                       }
                    </div>
                    {showAddProject && <div>
                        <input
                            type="text"
                            value={nameNewProject}
                            onChange={(e)=>setNameNewProject(e.target.value)}
                        />
                        <button onClick={createNewProject}>Create</button>
                    </div>}
                    <Button onClick={()=>setShowAddProject(true)}>Add project</Button>

                </div>
            </div>
        </Card>
    )
}

export default Admin;