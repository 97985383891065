import {useState,useEffect} from 'react'
import styles from './ProfilePicture.module.scss'
import Button from '../ButtonDesigned'
import {isMobile} from 'react-device-detect';

const ProfilePicture = (props) => {
    const [imgError, setImgError] = useState(false);

    const url = `${process.env.REACT_APP_API_URL}/profile_pictures/${props.address}.png`

    const [srcProfilePicture, setSrcProfilePicture] = useState(url)

    useEffect(()=>{
        //Trick to reload image
        setSrcProfilePicture(`${url}?`+Date.now())
        setImgError(false)
    },[props.updateProfilePicture,url])

    const handleImgError = () => {
        if (!imgError) {
            setImgError(true);
        }
    };

    const click = () => {
        if(!isMobile){
            props.setCategoryFeatures("inventory")
            props.setShowInventory(true)
        }else{
            props.showMobileInventory()
        }
    }
    return(
        <>
            <div className={styles.profilePicture}>
                <div className={styles.imageContainer}>
                    {imgError ? <p>Click to choose a profile picture</p> : <img onClick={click} alt="" src={srcProfilePicture} onError={handleImgError} /> }
                </div>
                <Button onClick={click}>Choose a profile picture</Button>
            </div>
        </>
    )
}

export default ProfilePicture;