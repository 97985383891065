import styles from "./PopupInfoFirst.module.scss"


const PopupInfoFirst = ({onClose}) => {

    
    return(
        <>
            
            <div className="bg" onClick={onClose}></div>
            <div className={styles.popup}>
                <h2>Congratulations you are 1st</h2>
                <p>Now when you have all the required attributes you don't generate a new series</p>
            </div>
        </>
    )
}

export default PopupInfoFirst;