import {useEffect,useState} from 'react'
import styles from "./PopupTresory.module.scss"
import Button from "../ButtonDesigned";
import closeImg from "../../assets/img/close.svg";

import axios from "axios"
import background from "../../assets/img/tresory-background.png"
import PopupTresoryDetails from "./PopupTresoryDetails"

const PopupDisclaimer = ({onClose,idProjet}) => {

    const [prices,setPrices] = useState([])
    const [showDetails,setShowDetails] = useState(undefined)

    const [isImg,setIsImg] = useState(false)


    useEffect(()=>{
        const fetchData = async () => {
            let response = await axios.get(process.env.REACT_APP_API_URL+"/get_prices?projectId="+idProjet)
            setPrices(response.data[0].contest.contestPrices)
        }
        fetchData()
    },[idProjet])

    useEffect(()=>{
        document.body.style.overflow = 'hidden';
        return ()=>document.body.style.overflow = 'unset';
    })

    const close = () => {
        onClose()
        setShowDetails(undefined)
        setIsImg(false)
    }

    const selection=(price)=>{
        setShowDetails(price.nftPrice && price.nftPrice.nftPriceDetails.length > 0 ? price.nftPrice.nftPriceDetails : undefined)
        setIsImg(true)
    }
    return(
        <>
            
            <div className="bg" onClick={close}></div>
            <div className={styles.popup}>
                {showDetails && <PopupTresoryDetails isImg={isImg} informations={showDetails} onClose={()=>setShowDetails(undefined)} />}
                <img onClick={close} className={styles.close} alt="" src={closeImg}/>
                <img alt="" src={background} className={styles.background} />
                <Button className={styles.button}>
                    Holder Contest<br/>
                    Global Value
                </Button>

                <table className={styles.table}>
                    <thead>
                        <tr>
                            <td><div>Ranking</div></td>
                            <td><div>EGLD</div></td>
                            <td><div>Token</div></td>
                            <td><div>NFT / SFT</div></td>
                            <td><div>Other</div></td>
                            <td><div>Total value</div></td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            prices.map((price)=>
                                <tr id={price.id}>
                                    <td>{price.placeOrder}{price.placeOrder===1 ? "st" : (price.placeOrder===2 ? "nd" : (price.placeOrder===3 ? "rd" : "th"))}</td>
                                    <td>{price.egldPrice ? price.egldPrice.value : "-"}</td>
                                    <td className={styles.pointer} onClick={()=>setShowDetails(price.tokenPrice && price.tokenPrice.tokenPriceDetails.length > 0 ? price.tokenPrice.tokenPriceDetails : undefined)}>{price.tokenPrice ? price.tokenPrice.displayedText : "-"}</td>
                                    <td className={styles.pointer} onClick={()=>selection(price)}>{price.nftPrice ? price.nftPrice.displayedText : "-"}</td>
                                    <td className={styles.pointer} onClick={()=>setShowDetails(price.otherPrice && price.otherPrice.otherPriceDetails.length > 0 ? price.otherPrice.otherPriceDetails : undefined)}>{price.otherPrice ? price.otherPrice.displayedText : "-"}</td>
                                    <td>{Math.floor(price.totalValue*100)/100} EGLD</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default PopupDisclaimer;