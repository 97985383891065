
import ButtonDesigned from "../components/ButtonDesigned"
import { useNavigate } from 'react-router-dom';
import {useGetLoginInfo} from '@multiversx/sdk-dapp/hooks/account';

function Error404(){
    const navigate = useNavigate();
    const {isLoggedIn} = useGetLoginInfo()

    const navigateTo = () => {
        if(isLoggedIn){
            navigate("/")
        }else{
            navigate("/login")
        }
    }

    return(
        <div className="page-404">
                <p>This page doesn't exist</p>
                <ButtonDesigned className={"button-404"} onClick={navigateTo}>Go to {isLoggedIn ? "home" : "login"} page</ButtonDesigned>
        </div>
    )
}

export default Error404