// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SignTransactionAlert_popup__2lIG5 {\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  -webkit-transform: translate(-50%, -50%);\n          transform: translate(-50%, -50%);\n  z-index: 301;\n  width: 453px;\n  height: 232px;\n  background: #2F2F2F;\n  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.75);\n  border-radius: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.SignTransactionAlert_background__1XSpN {\n  position: fixed;\n  height: 100vh;\n  width: 100vw;\n  top: 0;\n  left: 0;\n  background: gray;\n  z-index: 300;\n  opacity: 0.6;\n}", "",{"version":3,"sources":["webpack://src/components/SignTransactionAlert/SignTransactionAlert.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,QAAA;EACA,SAAA;EACA,wCAAA;UAAA,gCAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,2CAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACJ;;AACA;EACI,eAAA;EACA,aAAA;EACA,YAAA;EACA,MAAA;EACA,OAAA;EACA,gBAAA;EACA,YAAA;EACA,YAAA;AAEJ","sourcesContent":[".popup{\n    position: fixed;\n    top:50%;\n    left:50%;\n    transform: translate(-50%,-50%);\n    z-index:301;\n    width: 453px;\n    height: 232px;\n    background: #2F2F2F;\n    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.75);\n    border-radius: 10px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n.background{\n    position: fixed;\n    height: 100vh;\n    width: 100vw;\n    top: 0;\n    left: 0;\n    background: gray;\n    z-index: 300;\n    opacity: 0.6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": "SignTransactionAlert_popup__2lIG5",
	"background": "SignTransactionAlert_background__1XSpN"
};
export default ___CSS_LOADER_EXPORT___;
