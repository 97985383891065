import AdminComponent from "../components/AdminProjects"
import { useEffect,useState,useContext } from "react"
import {Context} from '../ContextProvider'
import axios from "axios";
import { useParams } from 'react-router-dom';

function AdminProjects(){
    const { id } = useParams();
    const { jwtToken } = useContext(Context);

    const [isAdmin,setIsAdmin] = useState(false)
    useEffect(()=>{
        const checkIfIsAdmin = async() => {
            try{
                await axios.get(process.env.REACT_APP_API_URL+"/check_is_admin",{headers: { Authorization: `Bearer ${jwtToken}` }})
                setIsAdmin(true)
            }catch{
                setIsAdmin(false)
            }
        }
        if(jwtToken){
            checkIfIsAdmin()

        }
    },[jwtToken])
    return(
        <div className="dao-page">
            <AdminComponent id={id} isAdmin={isAdmin}/>
        </div>
    )
}

export default AdminProjects