import {useState,useEffect} from "react"
import styles from "./Layout.module.scss";
import Tab from "../Tab"
import logo from "./assets/img/logo.png"
import heart from "./assets/img/heart.svg"
import gearImg from "../../assets/img/gear.png"
import ButtonDesigned from "../ButtonDesigned"
import MenuMobile from "../MenuMobile"
import {useGetLoginInfo,useGetAccountInfo} from '@multiversx/sdk-dapp/hooks/account';
import {logout} from '@multiversx/sdk-dapp/utils';
import routes from "../../routes";
import {useLocation,useNavigate} from 'react-router-dom';
import axios from "axios"

import Account from "../Account";



const Layout = (props) => {
    const location = useLocation();

    const [showMenuMobile, setShowMenuMobile] = useState(false)

    const {isLoggedIn} = useGetLoginInfo()

    let navigate = useNavigate();

    const { address } = useGetAccountInfo();


    const [discord,setDiscord] = useState("")
    const [facebook,setFacebook] = useState("")
    const [mail,setMail] = useState("")
    const [twitter,setTwitter] = useState("")
    const [user,setUser] = useState("")
    const [showMobileInventory,setShowMobileInventory] = useState(false)


    useEffect(()=>{

        if(isLoggedIn){
            const fetchData = async() => {
                try{
    
                    let response = await axios.post(`${process.env.REACT_APP_API_LINK}/get_user.php?address=${address}`)
                    
                    response.data.user_name!==null && setUser(response.data.user_name)
                    response.data.twitter!==null && setTwitter(response.data.twitter)
                    response.data.facebook!==null && setFacebook(response.data.facebook)
                    response.data.mail!==null && setMail(response.data.mail)
                    response.data.discord!==null && setDiscord(response.data.discord)
                }catch(error){
                    console.log(error)
                }
            }
    
            fetchData();
        }

    },[address,isLoggedIn])

    return(
        <>
            {showMobileInventory && <MenuMobile section="inventory" onClose={()=>setShowMobileInventory(false)} />}

            {showMenuMobile ? <MenuMobile user={user} facebook={facebook} discord={discord} twitter={twitter} mail={mail} setUser={setUser} setFacebook={setFacebook} setDiscord={setDiscord} setTwitter={setTwitter} setMail={setMail} onClose={()=>setShowMenuMobile(false)}/>
            
            :
            <div className={styles.layout}>
                <div className={styles.header}>
                    <img className={styles.logo} alt="logo" src={logo} onClick={()=>{props.setShowGame(undefined);navigate("/")}}/>
                    <div className={styles.tabs}>
                        {
                            routes.map(({showInTab,name,key,path})=>
                                showInTab && isLoggedIn && <Tab onClick={name==="Home" ? ()=>{props.setShowGame(undefined);navigate(path)}: ()=>navigate(path)} selected={location.pathname===path ? true : false} key={key}>{name}</Tab>
                            )
                        }
                    </div>
                    {isLoggedIn && <ButtonDesigned onClick={logout} className={styles.logout}>Logout</ButtonDesigned>}
                    {isLoggedIn && <img className={styles.gear} onClick={()=>setShowMenuMobile(true)} src={gearImg} alt="gear"/>}
                </div>

                <div className={styles.body}>
                    <div className={styles.content}>
                        {isLoggedIn && <Account showMobileInventory={()=>setShowMobileInventory(true)} user={user} facebook={facebook} discord={discord} twitter={twitter} mail={mail} setUser={setUser} setFacebook={setFacebook} setDiscord={setDiscord} setTwitter={setTwitter} setMail={setMail}/>}
                        {props.children}
                    </div>
                    
                    <div className={styles.footer}>Made with&nbsp;<img alt="heart" src={heart}/>&nbsp;by Elrond Ants Club.</div>
                </div>

            </div>
            }
        </>

    )
}

export default Layout;