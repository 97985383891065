import {useState,useEffect} from "react"
import styles from "./Login.module.scss"
import Card from "../Card"
import antsImg from "../../assets/img/ants-2.png"
import {WalletConnectLoginButton,WebWalletLoginButton} from "@multiversx/sdk-dapp/UI";
import axios from 'axios';


const Login = () => {
    const [token,setToken] = useState();

    const requestToken = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/request_token`)
        .then(function(response){
            console.log(response.data.token)
            setToken(response.data.token);
        })
        .catch((error)=>console.log(error))
    }

    useEffect(()=>{
        requestToken()
        let interval = setInterval(requestToken,60000)
        return ()=>clearInterval(interval)
    },[])


    return(
        <>

            <Card className={styles.cardLogin}>
                <div className={styles.login}>
                    <h2>Login</h2>
                    <div>
                        <div className={styles.image}><img alt="ants" src={antsImg}/></div>
                        <div className={styles.option}>
                            <WebWalletLoginButton
                                callbackRoute="/"
                                loginButtonText="Web Wallet"
                                buttonClassName={styles.button}
                                token={token}
                            />
                            <WalletConnectLoginButton 
                                callbackRoute={"/home"}
                                loginButtonText='xPortal'
                                title='xPortal Login'
                                logoutRoute='/login'
                                buttonClassName={styles.button}
                                lead='Scan the QR code using xPortal'
                                redirectAfterLogin={"/"}
                                isWalletConnectV2={true}
                                modalClassName={styles.maiarLogin}
                                token={token}
                            />
                        
                        </div>
                        <div className={styles.image}><img alt="ants" style={{transform:"scaleX(-1)"}} src={antsImg}/></div>
                    </div>
                </div>
            </Card>
        </>
    )
}

export default Login;