import styles from "./AdminKeyElement.module.scss"
import Card from "../Card"
import { useEffect,useContext,useState } from "react"
import axios from "axios"
import Button from "../Button"
import {Context} from '../../ContextProvider'
import { nonceToHexadecimal } from "../../utils"


const AdminKeyElement = ({isAdmin,id,type}) => {
    
    const { jwtToken } = useContext(Context);

    const [category,setCategory] = useState("home")
    

    const [dataProject, setDataProject] = useState()
    const [selectedCollection, setSelectedCollection] = useState()
    const [selectedCollectionNft, setSelectedCollectionNft] = useState()
    const [selectedNft, setSelectedNft] = useState()
    const [scoreNft, setScoreNft] = useState()


    const [score, setScore] = useState(1)



    const [selectedTrait, setSelectedTrait] = useState()
    const [selectedTraitValue, setSelectedTraitValue] = useState()

    const [picture, setPicture] = useState();
    const [pictureToSend, setPicturesToSend] = useState();

    const [pictureNft, setPictureNft] = useState();
    const [pictureToSendNft, setPicturesToSendNft] = useState();

    const [newName, setNewName] = useState("");
    const [newNft, setNewNft] = useState("");


    const [newNameTraitValue,setNewNameTraitValue] = useState("")
    const [newImageTraitValue,setNewImageTraitValue] = useState()

    useEffect(()=>{
        if(selectedTraitValue!==undefined){
            if(type==="key"){
                setPicture(`${process.env.REACT_APP_API_URL}/${dataProject.trait[0].name}/trait/${dataProject.trait[0].gameCollections[selectedCollection].gameTraits[selectedTrait].traitName}/${dataProject.trait[0].gameCollections[selectedCollection].gameTraits[selectedTrait].gameTraitValues[selectedTraitValue].value}.png`)

            }else{
                setPicture(`${process.env.REACT_APP_API_URL}/${dataProject.trait[0].name}/trait/${dataProject.trait[0].jokerCollections[selectedCollection].jokerTraits[selectedTrait].traitName}/${dataProject.trait[0].jokerCollections[selectedCollection].jokerTraits[selectedTrait].jokerTraitValues[selectedTraitValue].value}.png`)

            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedTraitValue])

    useEffect(()=>{
        if(selectedNft!==undefined){
            setScoreNft(nfts[selectedNft].score)
            if(type==="key"){
                setPictureNft(`${process.env.REACT_APP_API_URL}/${dataProject.nft[0].name}/nft/${dataProject.nft[0].gameCollections[selectedCollectionNft].idCollection}/${nfts[selectedNft].nonce}.png`)

            }else{
                setPictureNft(`${process.env.REACT_APP_API_URL}/${dataProject.nft[0].name}/nft/${dataProject.nft[0].jokerCollections[selectedCollectionNft].idCollection}/${nfts[selectedNft].nonce}.png`)

            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedNft])
   



    useEffect(()=>{

        const getTraits = async() => {
            try{
                let response = await axios.get(process.env.REACT_APP_API_URL+`/get_${type === "key" ? "traits" : "jokers"}?idProjet=`+id)
                
                let response2 = await axios.get(process.env.REACT_APP_API_URL+`/get_${type === "key" ? "nft_key" : "nft_key"/* "nft_jokers" */}?idProjet=`+id)
                setDataProject({trait:response.data,nft:response2.data})
                
            }catch(e){
                console.log(e)
            }
        }
        if(type && id){
            getTraits()

        }
    },[type,id])

    const handleImageChange = (e) => {
        const selectedFile = e.target.files[0];
        setPicturesToSend(selectedFile)
        const objectUrl = URL.createObjectURL(selectedFile);
        setPicture(objectUrl);
    };

    const handleImageNftChange = (e) => {
        const selectedFile = e.target.files[0];
        setPicturesToSendNft(selectedFile)
        const objectUrl = URL.createObjectURL(selectedFile);
        setPictureNft(objectUrl);
    };

    const handleSubmit = (e) => {
       
        e.preventDefault();
        const formData = new FormData();
        formData.append("file", pictureToSend);
        if (type==="key") formData.append("id",  dataProject.trait[0].gameCollections[selectedCollection].gameTraits[selectedTrait].gameTraitValues[selectedTraitValue].id);
        if (type==="joker") formData.append("id",  dataProject.trait[0].jokerCollections[selectedCollection].jokerTraits[selectedTrait].jokerTraitValues[selectedTraitValue].id);
        axios.post(process.env.REACT_APP_API_URL+"/upload_trait_picture",
            formData,
            {headers: { 
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${jwtToken}` 
            }}
        ).then((response)=>{
            window.alert(response.data)
        }).catch((error)=>{
            window.alert(error);
        })
    }


    const handleSubmitUpdateNft = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("file", pictureToSendNft);
        formData.append("score", scoreNft);
        formData.append("projectId",  id);
        formData.append("idCollection",  collectionsNft[selectedCollectionNft].idCollection);
        formData.append("nonce",  nfts[selectedNft].nonce);
        formData.append("type",  type);

        axios.post(process.env.REACT_APP_API_URL+"/update_nft",
            formData,
            {headers: { 
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${jwtToken}` 
            }}
        ).then((response)=>{
            window.alert(response.data)
        }).catch((error)=>{
            window.alert(error);
        })
        
    }
    const removePicture = (e) => {

        axios.post(process.env.REACT_APP_API_URL+"/remove_trait_picture",
            {id:type==="key" ? dataProject.trait[0].gameCollections[selectedCollection].gameTraits[selectedTrait].gameTraitValues[selectedTraitValue].id : dataProject.trait[0].jokerCollections[selectedCollection].jokerTraits[selectedTrait].jokerTraitValues[selectedTraitValue].id},
            {headers: { 
                Authorization: `Bearer ${jwtToken}` 
            }}
        ).then((response)=>{
            window.alert(response.data)
            setPicture(``)
        }).catch((error)=>{
            window.alert(error);
        })
    }

    const removeTrait=()=>{
        if(type==="key"){
            axios.post(process.env.REACT_APP_API_URL+"/remove_trait_value",
                {id:dataProject.trait[0].gameCollections[selectedCollection].gameTraits[selectedTrait].gameTraitValues[selectedTraitValue].id},
                {headers: { 
                    Authorization: `Bearer ${jwtToken}` 
                }}
            ).then((response)=>{
                window.alert(response.data)
                let data = dataProject.trait
                data[0].gameCollections[selectedCollection].gameTraits[selectedTrait].gameTraitValues.splice(selectedTraitValue,1)
                setSelectedTraitValue(undefined)
                setDataProject({trait:data,nft:dataProject.nft})
            }).catch((error)=>{
                window.alert(error);
            })
        }else{
            axios.post(process.env.REACT_APP_API_URL+"/remove_joker_trait_value",
            {id:dataProject.trait[0].jokerCollections[selectedCollection].jokerTraits[selectedTrait].jokerTraitValues[selectedTraitValue].id},
            {headers: { 
                Authorization: `Bearer ${jwtToken}` 
            }}
            ).then((response)=>{
                window.alert(response.data)
                let data = dataProject.trait
                data[0].jokerCollections[selectedCollection].jokerTraits[selectedTrait].jokerTraitValues.splice(selectedTraitValue,1)
                setSelectedTraitValue(undefined)
                setDataProject({trait:data,nft:dataProject.nft})
            }).catch((error)=>{
                window.alert(error);
            })        
        }
        
    }

    const createNewTrait=()=>{
        axios.post(process.env.REACT_APP_API_URL+`/create_trait${type==="joker" ? "_joker" : ""}`,
            {id:type==="key" ? dataProject.trait[0].gameCollections[selectedCollection].id : dataProject.trait[0].jokerCollections[selectedCollection].id ,
            name:newName},
            {headers: { 
                Authorization: `Bearer ${jwtToken}` 
            }}
        ).then((response)=>{
            window.alert(response.data)
            
        }).catch((error)=>{
            window.alert(error);
        })
    }

    const createNewNft=()=>{
        axios.post(process.env.REACT_APP_API_URL+`/create_nft${type==="joker" ? "_joker" : ""}`,
            {idProjet:id ,
            idNft:newNft,
            score
        },
            {headers: { 
                Authorization: `Bearer ${jwtToken}` 
            }}
        ).then((response)=>{
            window.alert(response.data)

            setCategory("nft")
        }).catch((error)=>{
            window.alert(error);
        })
    }

    const handleSubmitNewTraitValue=(e)=>{
        e.preventDefault();
        const formData = new FormData();
        formData.append("file", newImageTraitValue);
        formData.append("name", newNameTraitValue);
        formData.append("idTrait", type==="key" ? dataProject.trait[0].gameCollections[selectedCollection].gameTraits[selectedTrait].id : dataProject.trait[0].jokerCollections[selectedCollection].jokerTraits[selectedTrait].id);
        axios.post(process.env.REACT_APP_API_URL+`/create_trait_value${type==="joker" ? "_joker" : ""}`,
            formData,
            {headers: { 
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${jwtToken}` 
            }}
        ).then(()=>{
            setCategory("trait")
        }).catch((error)=>{
            window.alert(error);
        })
    }

    const handleRemoveTrait=()=>{
        axios.post(process.env.REACT_APP_API_URL+`/remove_${type==="key" ? "trait" : "joker"}`,
            {id:type==="key" ? dataProject.trait[0].gameCollections[selectedCollection].gameTraits[selectedTrait].id : dataProject.trait[0].jokerCollections[selectedCollection].jokerTraits[selectedTrait].id},
            {headers: { 
                Authorization: `Bearer ${jwtToken}` 
            }}
        ).then((response)=>{
            let newData = dataProject.trait
            if(type==="key") newData[0].gameCollections[selectedCollection].gameTraits.splice(selectedTrait,1)
            if(type==="gamejoker") newData[0].jokerCollections[selectedCollection].jokerTraits.splice(selectedTrait,1)
            setDataProject({trait:newData,nft:dataProject.nft})
            setSelectedTrait(undefined)
            
        }).catch((error)=>{
            window.alert(error);
        })
    }



    const [collections, setCollections] = useState([])
    const [traits, setTraits] = useState([])
    const [traitValues, setTraitValues] = useState([])

    const [collectionsNft, setCollectionsNft] = useState([])
    const [nfts, setNfts] = useState([])


    useEffect(()=>{
        if(dataProject!==undefined){

            let collectionsNew = type==="key" ? dataProject.trait[0].gameCollections : dataProject.trait[0].jokerCollections
            setCollections(collectionsNew)
            if(selectedCollection!==undefined){
                let traitsNew = type==="key" ? collections[selectedCollection].gameTraits : collections[selectedCollection].jokerTraits
                setTraits(traitsNew)
                if(selectedTrait!==undefined){
                    let traitValuesNew = type==="key" ? traits[selectedTrait].gameTraitValues : traits[selectedTrait].jokerTraitValues
                    setTraitValues(traitValuesNew)
                }
            }

            let collectionsNewNft = type==="key" ? dataProject.nft[0].gameCollections : dataProject.nft[0].jokerCollections
            setCollectionsNft(collectionsNewNft)
            if(selectedCollectionNft!==undefined){
                let nftsNews = type==="key" ? collectionsNft[selectedCollectionNft].gameNfts : collectionsNft[selectedCollectionNft].jokerNfts
                setNfts(nftsNews)
               
            }
        }
    },[dataProject,selectedCollection,selectedTrait,collections,traits,type,selectedCollectionNft,collectionsNft,selectedNft])
    
    
    

    return(            
        <Card>
            <div className={styles.admin}>
                {
                    category === "home" && <>
                        <Button onClick={()=>setCategory("trait")}>Trait</Button>
                        <Button onClick={()=>setCategory("nft")}>NFT</Button>
                    </>
                }
                {
                    (category === "trait") && <>
                        {selectedCollection===undefined ?(
                            <>
                            {collections.map((collection,index)=>
                                <Button onClick={()=>setSelectedCollection(index)}>{collection.idCollection}</Button>
                            )}
                            </>
                        ):(
                            <>
                            {selectedTrait===undefined ?(
                                <>
                                    <div className={styles.traitValue}>
                                        {traits.map((trait,index)=>
                                            <Button className={styles.button2} onClick={()=>setSelectedTrait(index)} >{trait.traitName}</Button>
                                        )}
                                    </div>
                                    <Button className={styles.button} onClick={()=>setCategory("newTrait")}>Add new trait</Button>
                                </>
                            ):(
                                <>
                                {selectedTraitValue===undefined ?(
                                    <>
                                    <div className={styles.traitValue}>
                                        {traitValues.map((traitValue,index)=>
                                            <Button className={styles.button2} onClick={()=>setSelectedTraitValue(index)}>{traitValue.value}</Button>

                                        )}
                                    </div>
                                    <Button className={styles.button} onClick={()=>setCategory("newTraitValue")}>Add new trait value</Button>
                                    <Button className={styles.button} onClick={handleRemoveTrait}>Remove trait</Button>

                                    </>

                                ):(
                                    <div>
                                        <h3>Set up trait</h3>
                                        <form onSubmit={handleSubmit}>
                                            {picture && <img style={{width:"100px"}} src={picture} alt="Empty" />}
                                            <div>
                                                <label htmlFor="file">New picture :</label>
                                                <input
                                                    type="file"
                                                    id="filePictureBg"
                                                    accept=".png"
                                                    onChange={handleImageChange}
                                                />
                                            </div>
                                            
                                            <button type="submit">Change picture</button>
                                            <button type="button" onClick={removePicture}>Remove picture</button>
                                        </form>
                                        <Button onClick={removeTrait}>Remove</Button>
                                    </div>
                                )}
                                </>
                            )}
                            </>
                        )
                        }
                    </>
                }
                {
                    category==="newTrait" &&
                    <div>
                        <label>Name : </label><input value={newName} onChange={(e)=>setNewName(e.target.value)}/>
                        <Button onClick={createNewTrait}>Create</Button>
                    </div>
                }
                {
                    category==="newTraitValue" &&
                    <form onSubmit={handleSubmitNewTraitValue}>
                        <div>
                            <label htmlFor="filePictureTraitValue">New picture :</label>
                            <input
                                type="file"
                                id="filePictureTraitValue"
                                accept=".png"
                                onChange={(e)=>setNewImageTraitValue(e.target.files[0])}
                            />
                            <label htmlFor="name">Name :</label>
                            <input
                                id="name"
                                value={newNameTraitValue}
                                onChange={(e)=>setNewNameTraitValue(e.target.value)}
                            />
                        </div>
                        
                        <button type="submit">Create trait value</button>
                    </form>
                }
                {
                    category==="nft" &&
                    <>
                        {selectedCollectionNft===undefined ?(
                            <>
                            {collectionsNft.map((collection,index)=>
                                <Button onClick={()=>setSelectedCollectionNft(index)}>{collection.idCollection}</Button>
                            )}
                            <Button className={styles.button} onClick={()=>setCategory("newNft")}>Add new nft</Button>
                            </>
                        ):(<>
                            {selectedNft===undefined ?(
                                <>
                                    <div className={styles.traitValue}>
                                        {nfts.map((nft,index)=>
                                            <Button className={styles.button2} onClick={()=>setSelectedNft(index)} >{collectionsNft[selectedCollectionNft].idCollection+"-"+nonceToHexadecimal(nft.nonce)}</Button>
                                        )}
                                    </div>
                                    
                                </>
                            ):(
                                <>
                                <div>
                                    <h3>Set up NFT - {dataProject.nft[0].gameCollections[selectedCollectionNft].idCollection+"-"+nonceToHexadecimal(nfts[selectedNft].nonce)}</h3>
                                    <form onSubmit={handleSubmitUpdateNft}>
                                        <div>
                                            {pictureNft && <img style={{width:"100px"}} src={pictureNft} alt="Empty" />}
                                            <label htmlFor="filePictureTraitValue">New picture :</label>
                                            <input
                                                type="file"
                                                id="filePictureTraitValue"
                                                accept=".png"
                                                onChange={handleImageNftChange}
                                            />
                                            <label htmlFor="name">score :</label>
                                            <input
                                                id="name"
                                                value={scoreNft}
                                                onChange={(e)=>setScoreNft(e.target.value)}
                                            />
                                        </div>
                                        
                                        <button type="submit">Update NFT</button>
                                    </form>
                                </div>
                                </>
                            )}
                            </>
                        )}
                    </>
                }
                {
                    category==="newNft" &&
                    <div>
                        <label>Id : </label><input value={newNft} onChange={(e)=>setNewNft(e.target.value)}/>
                        <label>Score : </label><input type="number" value={score} onChange={(e)=>setScore(e.target.value)}/>
                        <Button onClick={createNewNft}>Create</Button>
                    </div>
                }
                
            </div>
        </Card>
    )
}

export default AdminKeyElement;