// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ButtonDesigned_button__3F6GI {\n  background-color: #6249FA;\n  border-radius: 20px;\n  font-family: \"Bangers\";\n  font-size: 20px;\n  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black, 0px 4px 4px rgba(0, 0, 0, 0.76);\n  color: white;\n  border: none;\n  padding: 9px 20px;\n  cursor: pointer;\n  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);\n}", "",{"version":3,"sources":["webpack://src/components/ButtonDesigned/ButtonDesigned.module.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,mBAAA;EACA,sBAAA;EACA,eAAA;EAEA,kGAAA;EACA,YAAA;EACA,YAAA;EACA,iBAAA;EACA,eAAA;EACA,0CAAA;AAAJ","sourcesContent":[".button{\n    background-color: #6249FA;\n    border-radius: 20px;\n    font-family: \"Bangers\";\n    font-size:20px;\n    //text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black,0px 4px 4px rgba(0, 0, 0, 0.76);\n    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black, 0px 4px 4px rgba(0, 0, 0, 0.76);\n    color:white;\n    border: none;\n    padding: 9px 20px;\n    cursor: pointer;\n    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "ButtonDesigned_button__3F6GI"
};
export default ___CSS_LOADER_EXPORT___;
