import { useState,useEffect } from "react"
import { useNavigate,useParams } from 'react-router-dom';

import GameModeSelector from "../components/GameModeSelector"
import {isMobile} from 'react-device-detect';
import rotate from '../assets/img/rotate.png'



function GameMobile({setShowGame}){
    const [isLandscape, setIsLandscape] = useState(false);
    const { id } = useParams();
    let navigate = useNavigate();
    useEffect(()=>{
        if(!isMobile){
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isMobile])

    const onNavigate=()=>{
      if(setShowGame){
        setShowGame(undefined)
      }
      navigate(`/`)
    }

    useEffect(() => {
      const updateIsLandscape = () => {
        setIsLandscape(window.innerHeight < window.innerWidth);
      };
  
      window.addEventListener('resize', updateIsLandscape);
      updateIsLandscape();
  
      return () => {
        window.removeEventListener('resize', updateIsLandscape);
      };
    }, []);

    return(
        <>
        {isLandscape ?  <GameModeSelector showMobile={true} id={id} onClose={onNavigate}/> : <div className="rotate-device"><img alt="" src={rotate}/><p>Please rotate the device</p></div>}
        </>
       
    )


}
export default GameMobile