// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditProfile_edit__2nQIV {\n  height: 70%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n  color: white;\n  max-width: 93%;\n  width: 542px;\n}", "",{"version":3,"sources":["webpack://src/components/EditProfile/EditProfile.module.scss"],"names":[],"mappings":"AACA;EACI,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,8BAAA;EACA,YAAA;EACA,cAAA;EACA,YAAA;AAAJ","sourcesContent":["\n.edit{\n    height:70%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: space-between;\n    color:white;\n    max-width: 93%;\n    width: 542px;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"edit": "EditProfile_edit__2nQIV"
};
export default ___CSS_LOADER_EXPORT___;
