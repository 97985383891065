import styles from "./Admin.module.scss"
import Card from "../Card"
import { useEffect,useContext,useState } from "react"
import axios from "axios"
import Button from "../Button"
import {Context} from '../../ContextProvider'
import { useNavigate } from 'react-router-dom';
import {ethers} from "ethers"
import SignTransactionAlert from "../SignTransactionAlert";
import {refreshAccount} from '@multiversx/sdk-dapp/utils/account';
import {sendTransactions} from '@multiversx/sdk-dapp/services'
import { useGetNetworkConfig,useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import {useSignTransactions} from '@multiversx/sdk-dapp/hooks/transactions';

import arrowImg from "../../assets/img/arrow-left.svg"

const { Address } = require('@elrondnetwork/erdjs');

function stringToHex(str) {
    let hex = "";
    for (let i = 0; i < str.length; i++) {
      let charCode = str.charCodeAt(i).toString(16);
      hex += charCode.padStart(2, "0");
    }
    return hex;
}

const AdminProjects = ({isAdmin,id}) => {
    const [file, setFile] = useState(null);
    const [collection, setCollection] = useState("");
    const { jwtToken } = useContext(Context);
    const [project,setProject] = useState([])
    const [gameFrequency,setGameFrequency] = useState([])
    const [comingSoon,setComingSoon] = useState()
    const [pictureCarousel, setPictureCarousel] = useState();
    const [picturesToSend,setPicturesToSend] = useState()
    const [pictureBg, setPictureBg] = useState();
    const [picturesToSendBg,setPicturesToSendBg] = useState()
    const [mode,setMode] = useState()
    const [dataPrice, setDataPrice] = useState([{tokenPrice:{displayedText:"",details:[]},nftPrice:{displayedText:"",details:[{text:"",image:null,url:""}]},otherPrice:{displayedText:"",details:[]},total:{quantity:0},egld:{quantity:0}}])
    const [nbRound, setNbRound] = useState()
    const [nbPrice, setNbPrice] = useState(1)
    const [toSend, setToSend] = useState(undefined)
    const {network}= useGetNetworkConfig()
    const [parameterDetailPopup, setParameterDetailPopup] = useState()
    const [selectedNftToSend,setSelectedNftToSend] = useState(0)
    const [selectedTokenToSend,setSelectedTokenToSend] = useState(0)

    const [quantityNft,setQuantityNft] = useState()
    const [quantityToken,setQuantityToken] = useState()
    const [quantityEgld,setQuantityEgld] = useState()

    const [textButtonLink,setTextButtonLink] = useState()
    const [externalLink,setExternalLink] = useState()




    const navigate = useNavigate();

    const [numberTrait,setNumberTrait] = useState()

    const { address } = useGetAccountInfo(); //to do : uncomment this line and remove next line
    //const address = "erd1tj46h7h332ln5kxhxpmk7fgsf8at2qys7k32tx0g56h6ygmh6xlqqrhduz"

    const [availableToken,setAvailableToken] = useState([])
    const [availableNFT,setAvailableNFT] = useState([])
    const [availableEGLD,setAvailableEGLD] = useState([])

    useSignTransactions();

    useEffect(()=>{
        const fetchToken = async () => {
            let response = await axios.get(`${network.apiAddress}/accounts/${address}/tokens?size=10000`)
            let newObjectQteToken=[]
            response.data.map((data)=>
                newObjectQteToken.push({identifier:data.identifier,amount:ethers.utils.formatUnits(data.balance,data.decimals),decimals:data.decimals})
            )
            setAvailableToken(newObjectQteToken)
        }

        const fetchNFT = async () => {

            let response = await axios.get(`${network.apiAddress}/accounts/${address}/nfts?size=10000`)
            let newObjectQteToken=[]
            response.data.map((data)=>
                newObjectQteToken.push({identifier:data.identifier,amount:(data.type==="SemiFungibleESDT" ? data.balance : 1),type:data.type})
            )
            setAvailableNFT(newObjectQteToken)
        }

        const fetchEGLD = async () => {
            let response = await axios.get(`${network.apiAddress}/accounts/${address}`)
            setAvailableEGLD(ethers.utils.formatUnits(response.data.balance,18))
        }

        if(network.apiAddress && address){
            fetchToken()
            fetchNFT()
            fetchEGLD()
        }

    },[address,network])

    const [bank,setBank]=useState({esdtEgld:[],nft:[]})
    useEffect(()=>{
        let interval
        if(mode==="key" && id){
            axios.get(process.env.REACT_APP_API_URL+"/get_bank?id="+id)
            .then((response)=>{
                setBank(response.data)
            })
            interval = setInterval(
                ()=>{
                    axios.get(process.env.REACT_APP_API_URL+"/get_bank?id="+id)
                    .then((response)=>{
                        setBank(response.data)
                    })
                }

            ,5000)
            
        }
        return () => {
            clearInterval(interval);
        };
    },[mode,id])

    useEffect(()=>{
        const getProject = async() => {
            try{
                let response = await axios.get(process.env.REACT_APP_API_URL+"/get_project?id="+id)
                setProject(response.data)
                setPictureCarousel(`${process.env.REACT_APP_API_URL}/${response.data.name}/carousel.png`)
                setPictureBg(`${process.env.REACT_APP_API_URL}/${response.data.name}/background.png`)
                setNumberTrait(response.data.keyNumber)
                setGameFrequency(response.data.frequencyGame)
                setComingSoon(response.data.comingSoon ? "yes" : "no")
                setMode(response.data.contest ? "contest" : "key")
                setExternalLink(response.data.externalLink)
                setTextButtonLink(response.data.textButtonLink)

                if(response.data.contest){
                    setNbRound(response.data.contest.roundMax)
                    const responsePrices = await axios.get(process.env.REACT_APP_API_URL+"/get_prices?projectId="+id)
                    let pricesNewData=[]

                    for(const responseItem of responsePrices.data[0].contest.contestPrices){
                        let newDetailsToken=[]

                        for(const detailsToken of responseItem.tokenPrice.tokenPriceDetails){
                            newDetailsToken.push(detailsToken.detailedText)
                        }


                        let newDetailsNft=[]
                        for(const detailsNft of responseItem.nftPrice.nftPriceDetails){
                            newDetailsNft.push({text:detailsNft.detailedText,image:null,url:detailsNft.url})
                        }
                        let newDetailsOther=[]
                        for(const detailsOther of responseItem.otherPrice.otherPriceDetails){
                            newDetailsOther.push(detailsOther.detailedText)
                        }
                        pricesNewData.push({
                            egld:{quantity:responseItem.egldPrice.value ?? 0},
                            total:{quantity:responseItem.totalValue ?? 0},
                            tokenPrice:{displayedText:responseItem.tokenPrice.displayedText,details:newDetailsToken},
                            nftPrice:{displayedText:responseItem.nftPrice.displayedText,details:newDetailsNft},
                            otherPrice:{displayedText:responseItem.otherPrice.displayedText,details:newDetailsOther}
                        })
                        
                    }
                    setDataPrice(pricesNewData)
                    setNbPrice(responsePrices.data[0].contest.contestPrices.length)
                }
            }catch(e){
                console.log(e)
            }
        }
        getProject()
    },[id])
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("file", file);
        formData.append("collection", collection);
        formData.append("project", project.name);


        // Envoyer le formulaire à l'API
        axios.post(process.env.REACT_APP_API_URL+"/setup_game_folder",formData,{headers: { Authorization: `Bearer ${jwtToken}`, 'Content-Type': 'multipart/form-data' }})
        .then((response)=>{
            window.alert(response.data)
        })
        .catch((error)=>{
            window.alert(error);
        })

    };
    const handleSubmit2 = (e) => {
        e.preventDefault();
        axios.post(process.env.REACT_APP_API_URL+"/update_project",
        {keyNumber:numberTrait,
        frequency:gameFrequency,
        comingSoon:comingSoon,
        externalLink:externalLink,
        textButtonLink:textButtonLink,
        id},
        {headers: { Authorization: `Bearer ${jwtToken}` }},
        )
        .then(()=>{
            window.alert("updated")
        })
        .catch(()=>{
            window.alert("error")
        })
    }
    const handleRadioChange = (e) => {
        setComingSoon(e.target.value);
    };

    const handleImageChange = (e) => {
        const selectedFile = e.target.files[0];
        setPicturesToSend(selectedFile)
        const objectUrl = URL.createObjectURL(selectedFile);
        setPictureCarousel(objectUrl);
    };

    const handleSubmit3 = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("file", picturesToSend);
        formData.append("id", id);
        axios.post(process.env.REACT_APP_API_URL+"/upload_carousel_picture",
            formData,
            {headers: { 
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${jwtToken}` 
            }}
        ).then((response)=>{
            window.alert(response.data)
        }).catch((error)=>{
            window.alert(error);
        })
    }

    const handleImageChange2 = (e) => {
        const selectedFile = e.target.files[0];
        setPicturesToSendBg(selectedFile)
        const objectUrl = URL.createObjectURL(selectedFile);
        setPictureBg(objectUrl);
    };
    const handleSubmit4 = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("file", picturesToSendBg);
        formData.append("id", id);
        axios.post(process.env.REACT_APP_API_URL+"/upload_background_picture",
            formData,
            {headers: { 
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${jwtToken}` 
            }}
        ).then((response)=>{
            window.alert(response.data)
        }).catch((error)=>{
            window.alert(error);
        })
    }

    const handleRadioModeChange = (e) => {
        setMode(e.target.value);
    };

    const handleChangePrice = (value, parameter, index) => {
        let newData= [...dataPrice]
        switch(parameter){
            case 'tokenPrice':
                newData[index].tokenPrice.displayedText = value
                setDataPrice(newData)
                break;
            case 'nftPrice':
                newData[index].nftPrice.displayedText = value
                setDataPrice(newData)
                break;
            case 'otherPrice':
                newData[index].otherPrice.displayedText = value
                setDataPrice(newData)
                break;  
            case 'egld':
                newData[index].egld.quantity = value
                setDataPrice(newData)
                break;  
            case 'total':
                newData[index].total.quantity = value
                setDataPrice(newData)
                break;  
            default:
                break
        }
    }

    const handleAddPrice = () => {
        let newData= [...dataPrice]
        newData.push({tokenPrice:{displayedText:"",details:[]},nftPrice:{displayedText:"",details:[{text:"",image:null,url:""}]},otherPrice:{displayedText:"",details:[]},egld:{number:0},total:{number:0}})
        setDataPrice(newData)
        setNbPrice(prev=>prev+1)
    }

    const handleRemovePrice = () => {
        let newData= [...dataPrice]
        newData.pop()
        setDataPrice(newData)
        setNbPrice(prev=>prev-1)
    }

    const PopupDetails = ({parameter}) => {
        const [dataPriceDetails,setDataPriceDetails] = useState([...dataPrice])
        const handleAddDetails = () => {
            let newData= [...dataPriceDetails]
            if(parameter.type !== "nftPrice"){
                newData[parameter.index][parameter.type].details.push("")
            }else{
                newData[parameter.index][parameter.type].details.push({text:"",image:null,url:""})
            }
            setDataPriceDetails(newData)
        }
        const removeDetails = () => {
            let newData= [...dataPriceDetails]
            newData[parameter.index][parameter.type].details.pop()
            setDataPriceDetails(newData)
        }
        const handleChangeDetails = (value,i) => {
            let newData= [...dataPriceDetails]
            if(parameter.type !== "nftPrice"){
                newData[parameter.index][parameter.type].details[i] = value
            }else{
                newData[parameter.index][parameter.type].details[i].text = value
            }
            setDataPriceDetails(newData)
        }
        const handleChangeImageDetails = (value,i) => {
            let newData= [...dataPriceDetails]
            newData[parameter.index][parameter.type].details[i].image = value
            newData[parameter.index][parameter.type].details[i].url = URL.createObjectURL(value)
            setDataPriceDetails(newData)
        }
        const validate = () => {
            setDataPrice([...dataPriceDetails])
            setParameterDetailPopup(undefined)
        }


        return(
            <div className={styles.popup}>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Nb
                            </th>
                            <th>
                                Detailed text
                            </th>
                            {
                                parameter.type==="nftPrice"&&
                                <th>
                                    Image
                                </th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {Array.from({length: dataPrice[parameter.index][parameter.type].details.length}, (_, i) => 
                            <tr key={i}>
                                <td>{i+1}</td>
                                <td>
                                    <input
                                        type="text"
                                        value={parameter.type !== "nftPrice" ? dataPrice[parameter.index][parameter.type].details[i] : dataPrice[parameter.index][parameter.type].details[i].text}
                                        onChange={(e)=>handleChangeDetails(e.target.value,i)}
                                    />
                                </td>  
                                {
                                    parameter.type==="nftPrice"&&
                                    <td>
                                        {dataPrice[parameter.index][parameter.type].details[i].image && <img style={{width:"100px"}} src={dataPrice[parameter.index][parameter.type].details[i].url} alt="Empty" />}
                                        <input
                                            type="file"
                                            accept=".png"
                                            onChange={(e)=>handleChangeImageDetails(e.target.files[0],i)}
                                        />
                                    </td>  
                                }                            
                            </tr>
                        )}
                    </tbody>
                </table>
                <button onClick={handleAddDetails}>Add new details</button>
                <button onClick={removeDetails}>Remove details</button>
                <Button onClick={validate}>Validate</Button>

            </div>
        )
    }

    const validationContest = () => {
        const formData = new FormData();
        for(const data of dataPrice){
            if(data.nftPrice.details.length > 0){
                for(const details of data.nftPrice.details){
                    if(details.image){
                        formData.append(`image`,details.image)
                        details.image=null
                    }
                    
                }

            }
        }
        formData.append(`projectId`,id)
        formData.append(`roundNb`,nbRound)
        formData.append(`prices`,JSON.stringify(dataPrice))
        axios.post(process.env.REACT_APP_API_URL+"/setup_contest",
        formData,
        {headers: { Authorization: `Bearer ${jwtToken}`, 'Content-Type': 'multipart/form-data' }})
        .then((response)=>{
            window.alert(response.data)
        })
        .catch((error)=>{
            window.alert(error);
        })
    }

    const handleChangeQuantityNft=(event)=>{
        let newValue = parseInt(event.target.value);
        if (newValue < 1) {
            newValue = 1;
        } else if (newValue > availableNFT[selectedNftToSend].amount) {
            newValue = availableNFT[selectedNftToSend].amount;
        }
        setQuantityNft(newValue);
    }


    const handleChangeQuantityToken=(event)=>{
        let newValue = parseFloat(event.target.value);
        if (newValue < 0.01) {
            newValue = 0.01;
        } else if (newValue > availableToken[selectedTokenToSend].amount) {
            newValue = availableToken[selectedTokenToSend].amount;
        }
        setQuantityToken(newValue);
    }

    const handleChangeQuantityEgld=(event)=>{
        let newValue = parseFloat(event.target.value);
        if (newValue < 0.01) {
            newValue = 0.01;
        } else if (newValue > availableEGLD) {
            newValue =availableEGLD;
        }
        setQuantityEgld(newValue);
    }

    const [waitingTransaction,setWaitingTransaction]=useState(false);

    const sendTransaction = async() => {
        if(!waitingTransaction){
            const contractAddress = process.env.REACT_APP_REWARDS_CONTRACT
            let transaction

            const hexAddresscontractAddress = new Address(contractAddress).hex();

            let projectHex = parseInt(id).toString(16)

            if(projectHex.length%2!==0){
                projectHex="0"+projectHex
            }

            if(toSend==="nft"){
                let prov = availableNFT[selectedNftToSend].identifier.split("-")
                let collId = prov.slice(0,2).join("-")
                let nonce = parseInt(prov[2]).toString(16)
                if(nonce.length%2!==0){
                    nonce="0"+nonce
                }
                let quantityToSend = quantityNft.toString(16)
                if(quantityToSend.length%2!==0){
                    quantityToSend="0"+quantityToSend
                }

                transaction = {
                    value: "0",
                    data: `ESDTNFTTransfer@${stringToHex(collId)}@${nonce}@${quantityToSend}@${hexAddresscontractAddress}@${stringToHex("deposit")}@${projectHex}`,
                    receiver: address,
                    gasLimit: 50_000_000,
                };
            }
            if(toSend==="token"){
                let quantityToSend = ethers.utils.parseUnits(quantityToken.toString(),availableToken[selectedTokenToSend].decimals)
                if(quantityToSend.length%2!==0){
                    quantityToSend="0"+quantityToSend
                }
               

                transaction = {
                    value: "0",
                    data: `ESDTTransfer@${stringToHex(availableToken[selectedNftToSend].identifier)}@${quantityToSend}@${stringToHex("deposit")}@${projectHex}`,
                    receiver: contractAddress,
                    gasLimit: 50_000_000,
                };

            }
            if(toSend==="egld"){
                transaction = {
                    value: ethers.utils.parseUnits(quantityEgld.toString(),18).toString(),
                    data: `deposit@${projectHex}`,
                    receiver: contractAddress,
                    gasLimit: 50_000_000,
                };
            }
            
            await refreshAccount();
            await sendTransactions({
                transactions: transaction,
                transactionsDisplayInfo:{
                    processingMessage: 'Processing transaction',
                    errorMessage: 'An error has occured during transaction',
                    successMessage: 'Success'
                },
                redirectAfterSign: false
            });

            setWaitingTransaction(true);
            
        }   
    }

    const [updateBankData,setUpdateBankData] = useState({nft:{},esdtEgld:{}})
    const updateBank = (newValue,type,asset,id)=>{
        let newBank = {...updateBankData}

        if(newBank[asset][id]===undefined){
            
            newBank[asset][id]={}
            newBank[asset][id][type]=newValue
        }else{
            newBank[asset][id][type]=newValue

        }
        setUpdateBankData(newBank)
    }

    const updateBankApi = (id,asset) => {
        axios.post(process.env.REACT_APP_API_URL+"/update_bank",
            {
                id,
                value:updateBankData[asset][id],
                type:asset
            },
            {headers: { Authorization: `Bearer ${jwtToken}`}})
        .then((response)=>{
            window.alert(response.data)
        })
        .catch((error)=>{
            window.alert(error);
        })


    }

    const removeProject = () => {
        axios.post(process.env.REACT_APP_API_URL+"/remove_project",
        {id},
        {headers: { Authorization: `Bearer ${jwtToken}` }},
        )
        .then((response)=>{
            window.alert(response.data)
            navigate(`/admin`)
        })
        .catch((error)=>{
            window.alert(error);
        })
    }

    const startProject=()=>{
        axios.post(process.env.REACT_APP_API_URL+"/start_project",
        {projectId:id},
        {headers: { Authorization: `Bearer ${jwtToken}`}}
        ).then((response)=>{
            window.alert(response.data)
            let newProject = {...project}

            newProject.active=true
            setProject(newProject)
        })
        .catch((error)=>{
            window.alert(error);
        })
    }

    const stopProject=()=>{
        axios.post(process.env.REACT_APP_API_URL+"/stop_project",
        {projectId:id},
        {headers: { Authorization: `Bearer ${jwtToken}`}}
        ).then((response)=>{
            window.alert(response.data)
            let newProject = {...project}
            newProject["active"]=false
            setProject(newProject)
        })
        .catch((error)=>{
            window.alert(error);
        })
    }

    const pauseProject=()=>{
        axios.post(process.env.REACT_APP_API_URL+"/pause_project",
        {projectId:id},
        {headers: { Authorization: `Bearer ${jwtToken}`}}
        ).then((response)=>{
            window.alert(response.data)
            let newProject = {...project}
            newProject["active"]=false
            setProject(newProject)
        })
        .catch((error)=>{
            window.alert(error);
        })
    }

    


    return(
        <Card>
            <img alt="" className={styles.arrow} src={arrowImg} onClick={()=>navigate("/admin")}/>

            {waitingTransaction && <SignTransactionAlert onClose={()=>{setWaitingTransaction(false)}}/>}

            {parameterDetailPopup && <PopupDetails parameter={parameterDetailPopup}/>}
            <div className={styles.admin}>
                <div>
                    <h2>Admin</h2>
                    <p style={{transform:"unset"}}>{project.name}</p>
                    <div className={styles.listProjects}>
                       {!isAdmin ?
                            <p>Sorry, you are not admin</p>
                        :
                            <div>
                                <div className={styles.form}>
                                    <h3>Set up new traits</h3>
                                    <form onSubmit={handleSubmit}>
                                        <div>
                                            <label htmlFor="file">Fichier ZIP :</label>
                                            <input
                                                type="file"
                                                id="file"
                                                accept=".zip"
                                                onChange={(e) => setFile(e.target.files[0])}
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="name">Collection Id :</label>
                                            <input
                                                type="text"
                                                id="name"
                                                value={collection}
                                                onChange={(e) => setCollection(e.target.value)}
                                            />
                                        </div>
                                        
                                        <button type="submit">Envoyer</button>
                                    </form>
                                </div>

                                <div className={styles.form}>
                                    <h3>Set up project parameters</h3>
                                    <form onSubmit={handleSubmit2}>
                                        
                                        <div>
                                            <label htmlFor="name">Key number :</label>
                                            <input
                                                type="number"
                                                id="number"
                                                value={numberTrait}
                                                onChange={(e) => setNumberTrait(e.target.value)}
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="name">Game frequency (Number of second between 2 keys):</label>
                                            <input
                                                type="number"
                                                id="frequency"
                                                value={gameFrequency}
                                                onChange={(e) => setGameFrequency(e.target.value)}
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="link">Link button:</label>
                                            <input
                                                type="text"
                                                id="link"
                                                value={externalLink}
                                                onChange={(e) => setExternalLink(e.target.value)}
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="textButtonLink">Name button link:</label>
                                            <input
                                                type="text"
                                                id="textButtonLink"
                                                value={textButtonLink}
                                                onChange={(e) => setTextButtonLink(e.target.value)}
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="name">Coming soon :</label>
                                            <input
                                                type="radio"
                                                value="yes"
                                                checked={comingSoon === 'yes'}
                                                onChange={handleRadioChange}
                                            />
                                            yes
                                            <input
                                                type="radio"
                                                value="no"
                                                checked={comingSoon === 'no'}
                                                onChange={handleRadioChange}
                                            />
                                            no
                                        </div>
                                        <button type="submit">Envoyer</button>
                                    </form>
                                </div>

                                <div className={styles.form}>
                                    <h3>Set up image carousel</h3>
                                    <form onSubmit={handleSubmit3}>
                                        {pictureCarousel && <img style={{width:"100px"}} src={pictureCarousel} alt="Empty" />}
                                        <div>
                                            <label htmlFor="file">New picture :</label>
                                            <input
                                                type="file"
                                                id="filePictureCarousel"
                                                accept=".png"
                                                onChange={handleImageChange}
                                            />
                                        </div>
                                        
                                        <button type="submit">Change picture</button>
                                    </form>
                                </div>

                                <div className={styles.form}>
                                    <h3>Set up background image</h3>
                                    <form onSubmit={handleSubmit4}>
                                        {pictureBg && <img style={{width:"100px"}} src={pictureBg} alt="Empty" />}
                                        <div>
                                            <label htmlFor="file">New picture :</label>
                                            <input
                                                type="file"
                                                id="filePictureBg"
                                                accept=".png"
                                                onChange={handleImageChange2}
                                            />
                                        </div>
                                        
                                        <button type="submit">Change picture</button>
                                    </form>
                                </div>
                                <div className={styles.form}>

                                    <h3>Project mode</h3>
                                    <div>
                                        <label htmlFor="name">Project mode :</label>
                                        <input
                                            type="radio"
                                            value="contest"
                                            checked={mode === 'contest'}
                                            onChange={handleRadioModeChange}
                                        />
                                        Constest
                                            <input
                                            type="radio"
                                            value="key"
                                            checked={mode === 'key'}
                                            onChange={handleRadioModeChange}
                                        />
                                        Key
                                    </div>
                                    <div>
                                        {mode === "contest" && <>
                                            <label>Number of round</label>
                                            <input
                                                type="number"
                                                id="nbRound"
                                                value={nbRound}
                                                onChange={(e) => setNbRound(e.target.value)}
                                            />
                                            <div>
                                                <h4>Prices</h4>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                Position
                                                            </th>
                                                            <th>
                                                                Token
                                                            </th>
                                                            <th>
                                                                Nft
                                                            </th>
                                                            <th>
                                                                Other
                                                            </th>
                                                            <th>
                                                                Egld
                                                            </th>
                                                            <th>
                                                                Total
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Array.from({length: nbPrice}, (_, i) => 
                                                            <tr key={i}>
                                                                <td>{i+1}</td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        value={dataPrice[i].tokenPrice.displayedText}
                                                                        onChange={(e)=>handleChangePrice(e.target.value,"tokenPrice",i)}
                                                                    />
                                                                    <div onClick={()=>setParameterDetailPopup({type:"tokenPrice",index:i})}>Details</div>
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        value={dataPrice[i].nftPrice.displayedText}
                                                                        onChange={(e)=>handleChangePrice(e.target.value,"nftPrice",i)}
                                                                    />
                                                                    <div onClick={()=>setParameterDetailPopup({type:"nftPrice",index:i})}>Details</div>
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        value={dataPrice[i].otherPrice.displayedText}
                                                                        onChange={(e)=>handleChangePrice(e.target.value,"otherPrice",i)}
                                                                    />
                                                                    <div onClick={()=>setParameterDetailPopup({type:"otherPrice",index:i})}>Details</div>
                                                                </td>
                                                                <td style={{"verticalAlign": "baseline"}}>
                                                                    <input
                                                                        type="number"
                                                                        value={dataPrice[i].egld.quantity}
                                                                        onChange={(e)=>handleChangePrice(e.target.value,"egld",i)}
                                                                    />
                                                                
                                                                </td>
                                                                <td style={{"verticalAlign": "baseline"}}>
                                                                    <input
                                                                        type="number"
                                                                        value={dataPrice[i].total.quantity}
                                                                        onChange={(e)=>handleChangePrice(e.target.value,"total",i)}
                                                                    />
                                                                   
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                <button onClick={handleAddPrice}>Add price</button>
                                                <button onClick={handleRemovePrice}>Remove price</button>
                                            </div>
                                            <Button onClick={validationContest}>Modify contest parameters</Button>
                                        </>}

                                        
                                        {mode === "key" && <div>
                                            <h3>To win</h3>
                                            <p>Nothing to win</p>
                                            <Button onClick={()=>setToSend("nft")}>Send NFT</Button>
                                            <Button onClick={()=>setToSend("token")}>Send Token</Button>
                                            <Button onClick={()=>setToSend("egld")}>Send EGLD</Button>

                                            {toSend && <div>
                                                {
                                                    toSend==="nft" && <>
                                                        <select value={selectedNftToSend} onChange={(e)=>{setSelectedNftToSend(e.target.value);setQuantityNft(1)}}>
                                                            {availableNFT.map((nft, index) => (
                                                                <option key={index} value={index}>
                                                                    {nft.identifier}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {
                                                            availableNFT[selectedNftToSend].type==="SemiFungibleESDT" &&
                                                            <>
                                                            <input
                                                                type="number"
                                                                value={quantityNft}
                                                                min="1"
                                                                max={availableNFT[selectedNftToSend].amount}
                                                                onChange={handleChangeQuantityNft}
                                                            />
                                                            </>
                                                        }
                                                        <Button onClick={sendTransaction}>Send</Button>

                                                    </>
                                                }
                                                {
                                                    toSend==="token" && <>
                                                        <select value={selectedTokenToSend} onChange={(e)=>{setSelectedTokenToSend(e.target.value);setQuantityToken(1)}}>
                                                            {availableToken.map((token, index) => (
                                                                <option key={index} value={index}>
                                                                    {token.identifier}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    
                                                        <input
                                                            type="number"
                                                            value={quantityToken}
                                                            min="0.01"
                                                            step="0.01"
                                                            max={availableToken[selectedTokenToSend].amount}
                                                            onChange={handleChangeQuantityToken}
                                                        />
                                                        <Button onClick={sendTransaction}>Send</Button>
                                                        
                                                        
                                                    
                                                    </>
                                                }
                                                {
                                                    toSend==="egld" && <>
                                                        <input
                                                            type="number"
                                                            value={quantityEgld}
                                                            min="0.01"
                                                            step="0.01"
                                                            max={availableEGLD}
                                                            onChange={handleChangeQuantityEgld}
                                                        />
                                                        <Button onClick={sendTransaction}>Send</Button>
                                                    </>
                                                }
                                            </div>}
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Token & EGLD</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Name</td>
                                                        <td>Quantity</td>
                                                        <td>Time</td>
                                                        <td>Permanent quantity to win</td>
                                                    </tr>
                                                    {
                                                        bank.esdtEgld.map((token,index)=>
                                                            <tr key={index}>
                                                                <td>{token.esdtEgldBankList.idEsdt==="0" ? "EGLD" : token.esdtEgldBankList.idEsdt}</td>
                                                                <td>{token.quantity}</td>
                                                                <td><input type="number" step="1" value={updateBankData.esdtEgld[token.id]?.time ?? token.time} onChange={(e)=>updateBank(e.target.value,"time","esdtEgld",token.id)}/></td>
                                                                <td><input type="number" step="1" value={updateBankData.esdtEgld[token.id]?.permanentQuantityToWin ?? token.permanentQuantityToWin} onChange={(e)=>updateBank(e.target.value,"permanentQuantityToWin","esdtEgld",token.id)}/></td>
                                                                <td><Button onClick={()=>updateBankApi(token.id,"esdtEgld")}>Update</Button></td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>NFT</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Name</td>
                                                        <td>Quantity</td>
                                                        <td>Time</td>
                                                        <td>Permanent quantity to win</td>
                                                    </tr>
                                                    {
                                                        bank.nft.map((nft,index)=>
                                                            <tr key={index}>
                                                                <td>{`${nft.nftBankList.idCollection}-${nft.nftBankList.nonce.toString(16)}`}</td>
                                                                <td>{nft.quantity}</td>
                                                                <td><input type="number" step="1" value={updateBankData.nft[nft.id]?.time ?? nft.time} onChange={(e)=>updateBank(e.target.value,"time","nft",nft.id)}/></td>
                                                                <td><input type="number" step="1" value={updateBankData.nft[nft.id]?.permanentQuantityToWin ?? nft.permanentQuantityToWin} onChange={(e)=>updateBank(e.target.value,"permanentQuantityToWin","nft",nft.id)}/></td>
                                                                <td><Button onClick={()=>updateBankApi(nft.id,"nft")}>Update</Button></td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>}
                                    </div>
                                </div>
                                <Button onClick={()=>navigate(`${/admin/}${id}/key`)}>key elements</Button>
                                <Button onClick={()=>navigate(`${/admin/}${id}/joker`)}>joker elements</Button>


                            </div>
                       }
                        {project.active ? <><Button onClick={stopProject}>Stop project</Button><Button onClick={pauseProject}>Pause project</Button></> : <Button onClick={startProject} >Start project</Button>}
                       <Button onClick={removeProject} className={styles.btnRemove}>Remove project</Button>
                    </div>

                </div>
            </div>
        </Card>
    )
}

export default AdminProjects;