import styles from "./PopupBonus.module.scss"


const PopupBonus = ({onClose,pointNumber}) => {

    return(
        <>
            <div className="bg" onClick={onClose}></div>
            <div className={styles.popup}>
                <p className={styles.title}>Random Point Bonus</p>
                
                <p>You are really lucky you just discovered a random point bonus</p>
                <div className={styles.totalPoint}>+ {pointNumber}</div>
                
            </div>
        </>
    )
}

export default PopupBonus;