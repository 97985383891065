import {useState} from "react"
import styles from "./Dao.module.scss"
import Card from "../Card"
import networkImg from "../../assets/img/network-img.png"
import infoImg from "../../assets/img/info.png"
import PopupDao from "../PopupDao"

const Dao = (props) => {

    const [showPopup,setShowPopup] = useState(false)

    const paragraph = [
        [1,"The objective of the Elrond ants club is to gather the enthusiasts of the ecosystem around a DAO at the service of its members and the Elrond network."],
        [2,"The DAO will be entirely managed by smart contracts to redistribute royalties or member access to various benefits. In addition, all important decisions will be voted on by the members before they are implemented."],
        [3,"Until the smart contracts necessary for this mode of operation are in place, the founders will apply redistribution rules. All smart contracts are developed, audited by our partner Conext and club members before being deployed on the main network."],
        [4,"The DAO is a non-profit organization, the revenues generated from staking, Masternodes or royalties, go back to the development and maintenance of the project as well as to the DAO members in the form of rewards or game winnings."],
        [5,"A dedicated Discord has been created so that club members can easily discuss and meet to develop or create new projects, applications and tools on this network."]
    ]


    return(            
        <Card>
            {showPopup && <PopupDao onClose={()=>setShowPopup(false)}/>}
            <div className={styles.dao}>
                <img src={networkImg} alt="network"/>
                <img onClick={()=>setShowPopup(true)} src={infoImg} alt="info"/>
                <div>
                    <h2>Ants Club Dao</h2>
                    <div>
                        {paragraph.map((item)=>
                            <p key={item[0]}>{item[1]}</p>
                        )}
                    </div>
                    <p>Coming Soon</p>
                </div>
            </div>
        </Card>
    )
}

export default Dao;