import styles from "./PopupTresoryDetails.module.scss"

const PopupDisclaimer = ({informations,onClose,isImg}) => {

    return(
        <>
            <div className="bg" onClick={onClose}></div>
            <div className={styles.popup + " " + (isImg ? styles.isImg : "") }>
                {
                    informations.map((information)=>
                        
                        <div className={styles.information} key={information.id}>
                            {(isImg && information.url) && <img alt='' style={{maxWidth:"100px"}} src={`${process.env.REACT_APP_API_URL}/${information.url}`}/>}
                            <span>{information.detailedText}</span>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default PopupDisclaimer;