import { useState,useEffect} from "react"
import styles from "./PopupFeaturesProfile.module.scss"
import Button from "../ButtonDesignedVar2"
import Inventory from "../Inventory"
import EditProfile from "../EditProfile"

const PopupFeaturesProfile = (props) => {

    const [toShow,setToShow]=useState(props.category ?? "edit" )


    useEffect(() => {
        
        document.body.style.overflow = 'hidden';
        return ()=> document.body.style.overflow = 'unset';
        
    }, []);

    return(
        <>
            <div className="bg" onClick={props.onClose}></div>
            <div className={styles.popupFeatures}>
                <div>
                    {/*<Button>My stat</Button>*/}
                    <Button selected={toShow==="inventory"} onClick={()=>setToShow("inventory")}>Inventory</Button>
                    <Button selected={toShow==="edit"} onClick={()=>setToShow("edit")}>Profil</Button>
                </div>
                {toShow==="edit" && <EditProfile user={props.user} facebook={props.facebook} discord={props.discord} twitter={props.twitter} mail={props.mail} setUser={props.setUser} setFacebook={props.setFacebook} setDiscord={props.setDiscord} setTwitter={props.setTwitter} setMail={props.setMail} onClose={props.onClose}/>}
                {toShow==="inventory" && <Inventory updateProfilePicture={props.updateProfilePicture}/>}
            </div>
            
        </>
    )
}

export default PopupFeaturesProfile;