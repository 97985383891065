import { useEffect, useState,useContext } from "react"
import styles from "./Inventory.module.scss"
import { useGetAccountInfo,useGetNetworkConfig } from '@multiversx/sdk-dapp/hooks';
import axios from "axios"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import {Context} from '../../ContextProvider'
import ImagesFrame from "./ImagesFrame/ImagesFrame";


const Inventory = (props) => {
    const { address } = useGetAccountInfo();
    const { network } = useGetNetworkConfig();

    const [nfts,setNfts] = useState([])
    const [selectedCollection,setSelectedCollection] = useState()


    const { jwtToken } = useContext(Context);


    useEffect(()=>{
        const fetchNft = async () => {
            try{
                let parsedNft=[]
                let response = await axios.get(`${network.apiAddress}/accounts/${address}/nfts?size=10000`)
                response.data.forEach((item)=>{
                    if(item.url){
                        let splitted=item.url.split(".")
                        let collection=item.collection
                        if(!(/^(mp4|mp3|json)$/.test(splitted[splitted.length - 1]))){
                            if(parsedNft[collection] !==undefined){
                                parsedNft[collection].push({id:item.identifier,url:item.url,name:item.name})
                            }else{
                                parsedNft[collection] = [{id:item.identifier,url:item.url,name:item.name}]
                            }
                        }
                    }
                })
                setNfts(parsedNft)
            }catch(error){
                console.log(error)
            }
        }
        fetchNft()
    },[address,network])

    const addToProfile = async (urlMedia) => {
        try{
            await axios.post(`${process.env.REACT_APP_API_URL}/upload_nft_profile_picture`,{media:urlMedia},{headers: { Authorization: `Bearer ${jwtToken}` }})
            props.updateProfilePicture()
        }catch(e){
            window.alert(e)
        }
        
    }
    
    return(
        <>
        {true || !(('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)) ? <div className={styles.inventory}> {/*Check if is not mobile*/}
            {
                selectedCollection ? (
                    <>
                        <div style={{position:"absolute",cursor:"pointer",top:"0",zIndex:"50"}} onClick={()=>setSelectedCollection(undefined)}>Return</div>

                        {nfts[selectedCollection].map((item)=>
                            <ImagesFrame url={item.url} name={item.name} isMobile={false} onClick={()=>addToProfile(item.url)} key={item.id}/>
                        )}
                        
                    </>
                    ):(
                    <>
                        {Object.keys(nfts).map((item)=>
                            <ImagesFrame url={nfts[item][0].url} name={item} isMobile={false} onClick={()=>setSelectedCollection(item)} key={item}/>
                        )}
                    </>
                    )
                
            }
        </div>:

        <div style={{width: "100%",position: "relative"}}>
            <Carousel infiniteLoop={true} showIndicators={false} showArrows={false} showStatus={false} useKeyboardArrows={false}>
            {
                nfts.map((item)=>
                    <ImagesFrame url={item.url} name={item.name} isMobile={true} onClick={()=>addToProfile(item.url)} key={item.id}/>
                )
            }
            </Carousel>
        </div>}
        </>

    )
}

export default Inventory;