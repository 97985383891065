import styles from "./GameTool.module.scss"
import Button from "../ButtonDesigned"

import arrowImg from "../../assets/img/arrow-left.svg"


const GameTool = ({onClose,redirectMint}) => {

    return(
        <>
            <img alt="" className={styles.arrow} src={arrowImg} onClick={onClose}/>

            <h2>Stats</h2>
            <div className={styles.tool}>
                
                <div>
                  To access the premimum tool, you must have an NFT ANTS CLUB
                </div>
                <Button onClick={redirectMint} className={styles.button}>Mint Ants NFT</Button>
            </div>
        </>
    )
}

export default GameTool;