import styles from "./SignTransactionAlert.module.scss"
const SignTransactionAlert = (props) => {
    return(
        <>
            <div className={styles.background} onClick={props.onClose}></div>
            <div className={styles.popup} >
                <p>Please sign the transaction</p>
            </div>
        </>
    )
}

export default SignTransactionAlert