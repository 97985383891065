// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProfilePicture_imageContainer__3_6fK {\n  height: 0;\n  overflow: hidden;\n}\n.ProfilePicture_imageContainer__3_6fK img {\n  object-fit: cover;\n  width: 100%;\n  height: 100%;\n}", "",{"version":3,"sources":["webpack://src/components/ProfilePicture/ProfilePicture.module.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,gBAAA;AACJ;AAAI;EACI,iBAAA;EACA,WAAA;EACA,YAAA;AAER","sourcesContent":[".imageContainer{\n    height:0;\n    overflow: hidden;\n    img{\n        object-fit: cover;\n        width: 100%;\n        height: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageContainer": "ProfilePicture_imageContainer__3_6fK"
};
export default ___CSS_LOADER_EXPORT___;
