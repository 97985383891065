import styles from "./Trait.module.scss"
import frameIt from "../../assets/img/frame-it.svg"


const Trait = (props) => {
    let style;
    if(props.orientation === "left"){
        style=styles.left
    }else if(props.orientation === "right"){
        style=styles.right
    }else if(props.orientation === "center-joker"){
        style=styles.centerJoker
    }else{
        style=styles.center
    }
    return(
        <div >
            <div onClick={props.onClick} className={styles.trait + " " +style} >
                {props.children}
                <a rel="noreferrer" target="_blank" href={props.marketplace} className={styles.marketplace + " " + (props.marketplace !== undefined ? styles.marketplaceVisible : "")}><img alt="" className={styles.image} src={frameIt}/></a>
            </div>
                
        </div>
    )
} 

export default Trait;