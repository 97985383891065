import Game from "../Game"
import GameContest from "../GameContest"
import { useEffect,useState } from "react";
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import axios from "axios"

const GameModeSelector = ({id,onClose}) => {
    const [contest,setContest] = useState(undefined);
    const { address } = useGetAccountInfo();


    useEffect(()=>{
        if(address && id){
            axios.get(process.env.REACT_APP_API_URL+"/get_key?idProjet="+id+"&address="+address).then((response)=>{
                if(response.data.contestInformation){

                    setContest(true)
                }else{
                    setContest(false)
                }
            })
        }
        
    },[address,id])
    return(
        <>
            {
                contest !== undefined && 
                <>{contest ? <GameContest onClose={onClose} id={id}/> : <Game onClose={onClose} id={id}/>}</>
            }
        </>
    )
}

export default GameModeSelector;