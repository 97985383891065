import Layout from "./components/Layout";
import routes from "./routes";
import { DappProvider } from '@multiversx/sdk-dapp/wrappers/DappProvider';
import {TransactionsToastList} from "@multiversx/sdk-dapp/UI/TransactionsToastList";
import { NotificationModal } from '@multiversx/sdk-dapp/UI/NotificationModal';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
//import '@elrondnetwork/dapp-core/dist/index.css';
import GameMobile from "./pages/GameMobile"
import AuthProvider  from "./ContextProvider";
import { AuthenticatedRoutesWrapper } from '@multiversx/sdk-dapp/wrappers/AuthenticatedRoutesWrapper';
import {  useState } from "react"
import Error404 from "./pages/Error404"


function App() {
  const [showGame,setShowGame]=useState(undefined)


  return (
    <DappProvider
        environment={process.env.REACT_APP_NETWORK}
        customNetworkConfig={{
          name: 'customConfig',
          walletConnectV2ProjectId: '2f048296b1522210fc86d465792f78a7'
        }}      
        dappConfig={{logoutRoute:"/"}}

    >
      <TransactionsToastList />
      <NotificationModal/>
      <AuthProvider>
        <AuthenticatedRoutesWrapper routes={routes} unlockRoute='/login'>
          <Router>
            <Routes>
              {
                routes.map(({path, component,key,name})=>
                  <Route key={key} exact path={path} element={<Layout setShowGame={setShowGame}>{name==="Home" ? <component.type showGame={showGame} setShowGame={setShowGame}/>: component}</Layout>} />
                )
              }
              <Route path="/game-mobile/:id" element={<GameMobile setShowGame={setShowGame}/>}/>
              <Route path='*' element={<Layout><Error404/></Layout>} />
            </Routes>
        </Router>
        </AuthenticatedRoutesWrapper>
        
      </AuthProvider>
    </DappProvider>
      
  );
}

export default App;
