import DaoComponent from "../components/Dao"

function Dao(){

    return(
        <div className="dao-page">
            <DaoComponent/>
        </div>
    )
}

export default Dao