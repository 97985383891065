import styles from "./Forbidden.module.scss"
const Forbidden = (props) => {
    return(
        <>
            <div className={styles.background} onClick={props.onClose}></div>
            <div className={styles.popup} >
                <p>You can't claim anymore, wait a little</p>
            </div>
        </>
    )
}

export default Forbidden