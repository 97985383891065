import { useEffect, useState } from "react"
import Card from "../Card"
import Pack from "../Pack"
import styles from "./CardMint.module.scss"
import networkImg from "./assets/img/network-img.png"
import facebookImg from "../../assets/img/facebook.png"
import discordImg from "../../assets/img/discord.png"
import twitterImg from "../../assets/img/twitter.png"
import antsImg from "./assets/img/ants.png"
import { ProxyNetworkProvider } from "@elrondnetwork/erdjs-network-providers"
import abiFile from "../../abi/mint.abi.json";
import BigInt from "big-integer"
import { Spinner } from 'loading-animations-react';

import {useGetNetworkConfig} from '@multiversx/sdk-dapp/hooks';
import {getAddress} from '@multiversx/sdk-dapp/utils/account';

import {
    Address,
    ContractFunction,
    Query,
    AbiRegistry,
    SmartContractAbi,
    SmartContract,
    ResultsParser,
    U8Value,
    AddressValue
} from '@elrondnetwork/erdjs';

const CardMint = (props) => {
    const { network } = useGetNetworkConfig();

    const[statut,setStatut] = useState("")
    const[infoSale,setInfoSale] = useState("")
    const[isWhitelist,setIsWhitelist] = useState(false)
    const[loading,setLoading] = useState(true)
    //const[nbMinted,setNbMinted] = useState(0)
    const[maxPerWhitelist,setMaxPerWhitelist] = useState(0)

    
    useEffect(()=>{
        const fetchDataMint = async () => {
            try{
                const contractAddress = process.env.REACT_APP_SMART_CONTRACT
                let abiRegistry = AbiRegistry.create(abiFile);
                let abi = new SmartContractAbi(abiRegistry, ["EmptyContract"]);
        
                let contract = new SmartContract({ address: new Address(contractAddress), abi: abi });
                const proxy = new ProxyNetworkProvider(network.apiAddress);
                let query= new Query({
                    address: new Address(contractAddress),
                    func: new ContractFunction('current_statut'),
                    args: []
                  });
                  
                let queryResponse=await proxy.queryContract(query)
                let endpointDefinition = contract.getEndpoint("current_statut");
                let { firstValue }  = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);
                let statutContract = firstValue.name
    
                let nbStatut={"Close":0,"Sale":1,"Presale1":2,"Presale2":3}
    
                setStatut(statutContract)
    
                if(statutContract==="Presale1" || statutContract==="Presale2" || statutContract==="Close"){
                    let functionContract
                    let functionContractNbPerWhitelist
                    if(statutContract==="Presale1" || statutContract==="Close" || statutContract==="Presale2"){
                        functionContract="whitelist_presale_1"
                        functionContractNbPerWhitelist="nb_per_whitelisted_presale_1"
                    }else{
                        functionContract="whitelist_presale_2"
                        functionContractNbPerWhitelist="nb_per_whitelisted_presale_2"
                    }
    
                    let address = await getAddress();
    
                    query= new Query({
                        address: new Address(contractAddress),
                        func: new ContractFunction(functionContract),
                        args: []
                    });
    
                    queryResponse=await proxy.queryContract(query)
                    endpointDefinition = contract.getEndpoint(functionContract);
                    let response = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);
                    let isWhitelist = response.firstValue.items.find((elem)=>elem.value.bech32()===address)

                    setIsWhitelist(isWhitelist!==undefined)
                    setLoading(false)
                    //setIsWhitelist(true)
                    query= new Query({
                        address: new Address(contractAddress),
                        func: new ContractFunction(functionContractNbPerWhitelist),
                        args: [new AddressValue(new Address(address))]
                    });
                        
                    queryResponse=await proxy.queryContract(query)
                    endpointDefinition = contract.getEndpoint(functionContractNbPerWhitelist);
                    response = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);
                    //let nbAlreadyMinted = response.firstValue.value
                    //setNbMinted(parseInt(nbAlreadyMinted,10))
    
                    query= new Query({
                        address: new Address(contractAddress),
                        func: new ContractFunction("nb_max_per_whitelist"),
                        args: []
                    });
                        
                    queryResponse=await proxy.queryContract(query)
                    endpointDefinition = contract.getEndpoint(functionContractNbPerWhitelist);
                    response = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);
                    let nbMaxPerWhitelist=response.firstValue.value
                    setMaxPerWhitelist(parseInt(nbMaxPerWhitelist,10))
                }else{
                    setLoading(false)
                }
    
    
                query= new Query({
                    address: new Address(contractAddress),
                    func: new ContractFunction('price_info'),
                    args: [new U8Value(nbStatut[statutContract!== "Close" ? statutContract : "Presale1" ])]
                });
                  
                queryResponse=await proxy.queryContract(query)
                endpointDefinition = contract.getEndpoint("price_info");
                let response = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);
    
                let newObject = {}
                newObject.price1 = response.firstValue.fields[0].value.value
                newObject.price2 = response.firstValue.fields[1].value.value
                newObject.nbAntsGamePrice1 = parseInt(response.firstValue.fields[2].value.value,10)
                newObject.nbAntsGamePrice2 = parseInt(response.firstValue.fields[3].value.value,10)
                newObject.nbAntsClubPrice1 = parseInt(response.firstValue.fields[4].value.value,10)
                newObject.nbAntsClubPrice2 = parseInt(response.firstValue.fields[5].value.value,10)
                newObject.nbAntsClubMax = parseInt(response.firstValue.fields[6].value.value,10)
                newObject.nbAntsClubSold = parseInt(response.firstValue.fields[7].value.value,10)
    
                setInfoSale(newObject)
            }catch(e){
                console.log(e)
                setLoading(false)
                //window.alert(e)
            }
            
            
        }

        try{
            fetchDataMint();
        }catch(error){
            console.log(error);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    return(
        <>
            {!loading && 
            <>
            {statut === "Close" ? (
                <>
                {isWhitelist ? (
                    <Card>
                        <img alt="network" src={networkImg} className={styles.background}/>
                        <div className={styles.card + " " + styles.cardAlt}>
                            <Pack inactive={true} price={0.5} soldOut={false} nbAntsClub={infoSale.nbAntsClubPrice1} nbAntsGame={infoSale.nbAntsGamePrice1} statut={"Presale 2"} pack={1}/>
                            <div>
                                <div className={styles.cardMint + " " +styles.cardMintAlt}>
                                    <img alt="ants" src={antsImg}/>
                                    <div className={styles.cardMintWhitelisted}>
                                        <h2>You are whitelisted</h2>
                                        <div className={styles.explanation}>Next sale on 23/02/2023<br/>7 PM UTC</div>
                                    </div>
                                    <img alt="ants" src={antsImg}/>
                                </div>
                            </div>
                            <Pack inactive={true} price={0.8} soldOut={false} nbAntsClub={infoSale.nbAntsClubPrice2} statut={"Presale 2"} nbAntsGame={infoSale.nbAntsGamePrice2} pack={2}/>
                        </div>
                    </Card>
                ) : (
                    <Card>
                        <img alt="network" src={networkImg} className={styles.background}/>
                        <div className={styles.card}>
                            <div className={styles.cardMint+ " " +styles.cardMintNotWl}>
                                <h2>Access the whitelist</h2>
                                <p>You can try your luck to be whitelisted by playing discord game or participating in events on our social networks</p>
                                <a style={{textDecoration:"none"}} href="https://twitter.com/ElrondAntsclub"><img alt="logo-twitter" src={twitterImg}/></a>
                                <a style={{textDecoration:"none"}} href="https://www.facebook.com/elrondants.club"><img alt="logo-fb" src={facebookImg}/></a>
                                <a style={{textDecoration:"none"}} href="https://discord.com/invite/z6yjVy86GD"><img alt="logo-discord" src={discordImg}/></a>
                            </div>
                        </div>
                    </Card>
                )}
                </>
            ) : (
                <>
                {isWhitelist ? (
                    <Card>
                        <img alt="network" src={networkImg} className={styles.background}/>
                        <div className={styles.card + " " + styles.cardAlt}>
                            <Pack soldOut={infoSale.nbAntsClubSold===infoSale.nbAntsClubMax} nbAntsClub={infoSale.nbAntsClubPrice1} nbAntsGame={infoSale.nbAntsGamePrice1} statut={statut} maxToMint={maxPerWhitelist} price={BigInt(infoSale.price1)/BigInt(10**18)} pack={1}/>
                            <div>
                                <div className={styles.cardMint + " " +styles.cardMintAlt}>
                                    <img alt="ants" src={antsImg}/>
                                    <div className={styles.cardMintWhitelisted}>
                                        <h2>You are whitelisted</h2>
                                        <div className={styles.explanation}>Choose the package quantity And buy</div>
                                        {/*<div>{nbMinted}/{maxPerWhitelist}</div>*/}
                                    </div>
                                    <img alt="ants" src={antsImg}/>
                                </div>
                                {/*<div>Quantity sold : {infoSale.nbAntsClubSold - 340}/{infoSale.nbAntsClubMax - 340}</div>*/}
                            </div>
                            <Pack  soldOut={infoSale.nbAntsClubSold===infoSale.nbAntsClubMax} nbAntsClub={infoSale.nbAntsClubPrice2} nbAntsGame={infoSale.nbAntsGamePrice2} statut={statut} maxToMint={maxPerWhitelist} price={BigInt(infoSale.price2)/BigInt(10**18)} pack={2}/>
                        </div>
                    </Card>
                ) : (

                    <Card>
                        <img alt="network" src={networkImg} className={styles.background}/>
                        <div className={styles.card}>
                            <div className={styles.cardMint+ " " +styles.cardMintNotWl}>
                                <h2>Access the whitelist</h2>
                                <p>You can try your luck to be whitelisted by playing discord game or participating in events on our social networks</p>
                                <a style={{textDecoration:"none"}} href="https://twitter.com/ElrondAntsclub"><img alt="logo-twitter" src={twitterImg}/></a>
                                <a style={{textDecoration:"none"}} href="https://www.facebook.com/elrondants.club"><img alt="logo-fb" src={facebookImg}/></a>
                                <a style={{textDecoration:"none"}} href="https://discord.com/invite/z6yjVy86GD"><img alt="logo-discord" src={discordImg}/></a>
                            </div>
                        </div>
                    </Card>
               
                )}
                </>
            )}
            </>}
            {loading &&
 
             <Card>
                <img alt="network" src={networkImg} className={styles.background}/>
                <div className={styles.card + " " + styles.cardAlt}>
                    <Spinner color1="#6249FA" color2="#3C305C" textColor="white" text={"Analyzing if you are whitelisted..."} />

                </div>
            </Card>

            }
        </>
    )
}

export default CardMint;