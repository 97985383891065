import styles from "./PopupSelectJoker.module.scss"
import Button from "../ButtonDesigned"
import arrowLeft from "./assets/arrow-left.svg"
import arrowRight from "./assets/arrow-right.svg"
import {useState} from "react"
import {nonceToHexadecimal} from "../../utils"


const PopupSelectJoker = ({randomJoker, permanentJoker, action,keyIndex,onClose}) => {
    const [selectedCategory,setSelectedCategory] = useState("random")
    const [index,setIndex]=useState(0)

    function decreaseIndex(){
        let max = selectedCategory === 'random' ? randomJoker.length : permanentJoker.length
        if(index === 0){
            setIndex(max-1)
        }else{
            setIndex(index-1)
        }
    }

    function increaseIndex(){

        let max = selectedCategory === 'random' ? randomJoker.length : permanentJoker.length
        if(index === max - 1){
            setIndex(0)
        }else{
            setIndex(index+1)
        }
    }

    return(
        <>
        <div className={styles.background} onClick={onClose}></div>
        <div className={styles.popupJoker} >
            <h2>Choose a joker to validate this attribute</h2>
            <div className={styles.category}><div onClick={()=>{setIndex(0);setSelectedCategory("random")}} className={selectedCategory==="random" && styles.selected}>Random Joker</div><div onClick={()=>{setIndex(0);setSelectedCategory("permanent")}} className={selectedCategory==="permanent" && styles.selected}>Permanent Joker</div></div>
            <div className={styles.selection}>
                {((selectedCategory === "random" && randomJoker.length > 0) || (selectedCategory === "permanent" && permanentJoker.length > 0)) ?
                    <>
                        <img alt="" src={arrowLeft} onClick={decreaseIndex}/>
                        <div>
                            {selectedCategory === "random" ? (randomJoker.length > 0 &&  `${randomJoker[index].nftId}-${nonceToHexadecimal(parseInt(randomJoker[index].nonce))}`) : (permanentJoker.length > 0 && `${permanentJoker[index].nftId}-${nonceToHexadecimal(parseInt(permanentJoker[index].nonce))}`)}
                        </div>
                        <img alt="" src={arrowRight} onClick={increaseIndex}/>
                    </>
                    :
                    <>No {selectedCategory} joker</>
                }
            </div>
            <Button onClick={()=>action(keyIndex,selectedCategory)} className={styles.btn}>Select</Button>
        </div>
        </>
    )
}

PopupSelectJoker.defaultProps = {
    randomJoker: [],
    permanentJoker :[]
  };

export default PopupSelectJoker;