import { useEffect } from "react";
import styles from "./PopupTokenAnts.module.scss"
import antsTokenImg from "../../assets/img/ants-token.png"


const PopupTokenAnts = (props) => {
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return ()=> document.body.style.overflow = 'unset';
     }, []);
    return(
        <>
            <div className="bg" onClick={props.onClose}></div>
            <div className={styles.popup}>
                <img alt="ants" src={antsTokenImg}/>
                <p>
                    The day of the public sale you can exchange your Ants token for an Nft
                </p>
            </div>
        </>
    )
}

export default PopupTokenAnts;