import {useState,useEffect} from 'react'
import styles from "./PopupDisclaimer.module.scss"
import Button from "../ButtonDesigned";

const PopupDisclaimer = (props) => {
    const [checked,setChecked] = useState(false)
    const [show,setShow] = useState(true)

    const validation = () => {
        setShow(false)
        if(checked){
            localStorage.setItem('disclaimer', true);
        }
    }

    useEffect(()=>{
        if(localStorage.getItem('disclaimer', true)){
            setShow(false)
        }
    },[])


    return(
        <>
            {
                show && <>
                <div className="bg"></div>
                    <div className={styles.popup}>
                        <h2>Welcome to the Ants Game!</h2>
                        <p>We are delighted to welcome you to a fun and fair gaming experience for all players.</p>

                        <p>However, we would like to inform you that the use of bots is strictly prohibited. Bots are not welcome in our community, and we don't want our players to feel that they are competing with machines rather than with other human players.</p>

                        <p>Similarly, we do not allow multiple players to group together by sending their NFTs to a wallet to create a group collection.<br/>
                        We want each player to have the opportunity to play fairly and individually.<br/>
                        Unauthorized pooling will result in serious consequences, including banning from the wallet address, exclusion from the leaderboard,<br/>
                        and even a ban on using NFTs, even after they are returned to the original wallets.</p>

                        <p>We know this sounds harsh, but we want to make sure that all players have a fair chance to win. <br/>
                        So let's play together in an honest and fun way!</p>

                        <p>Thank you for your understanding and have fun!</p>

                        <div className={styles.bottom}>
                            <Button className={styles.button} onClick={validation}>Enter</Button>
                            <div className={styles.information}>
                                <span onClick={()=>setChecked(!checked)} className={!checked ? styles.check: styles.checked}></span><span>Do not display this message again</span>
                            </div>
                        </div>
                    </div>
                </>
            } 
        </>
    )
}

export default PopupDisclaimer;