import {useEffect, useState,useContext} from "react"
import styles from "./Game.module.scss"
import Card from "../Card"
import PopupSelectJoker from "../PopupSelectJoker"
import PopupInfoAdditionalPoint from "../PopupInfoAdditionalPoint"
import PopupInfoFirst from "../PopupInfoFirst"
//import networkImg from "../../assets/img/network-img.png"
import ButtonDesigned from "../ButtonDesigned"
import frame from "../../assets/img/frame.png"
//import tresory2 from "../../assets/img/tresory2.png"

import Trait from "../Trait"
import axios from 'axios'
import { useGetNetworkConfig,useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import {isMobile} from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import {Context} from '../../ContextProvider'
import { Transition } from 'react-transition-group';
import { useRef } from 'react';
import {useSignTransactions} from '@multiversx/sdk-dapp/hooks/transactions';
import SignTransactionAlert from "../SignTransactionAlert";
import {nonceToHexadecimal} from "../../utils"
import GameTool from '../GameTool'
import GameToolPremium from '../GameToolPremium'

import arrowImg from "../../assets/img/arrow-left.svg"
import ForbiddenPopup from "../ForbiddenAlert"

import ContestRule from "../ContestRule"
import PopupDisclaimer from "../PopupDisclaimer"
import PopupTresory from "../PopupTresory"
import ScoreBoard from "../ScoreBoard"
import frameIt from "../../assets/img/frame-it.svg"

import PopupContestValidation from "../PopupContestValidation"
import PopupBonus from "../PopupBonus"




const duration = 300;

const defaultStyle = {
  transition: `left ${duration}ms ease-in-out`,
  left:'220px'
}

const transitionStyles = {
  entering: {left:'0px' },
  entered:  { left:'20px' },
  exiting:  {left:'0px'},
  exited:  { left:'220px' },
};




function base64ToUtf8(base64Str) {
    const binaryStr = atob(base64Str);
    const utf8Codes = new Uint8Array(binaryStr.length);
    for (let i = 0; i < binaryStr.length; i++) {
      utf8Codes[i] = binaryStr.charCodeAt(i);
    }
    const utf8Decoder = new TextDecoder();
    const utf8Str = utf8Decoder.decode(utf8Codes);
    return utf8Str;
  }
  
function GameImage ({src,alt}) {
    const [imgError, setImgError] = useState(false);
    const handleImgError = () => {
        if (!imgError) {
            setImgError(true);
        }
    };

    const [isPortrait, setIsPortrait] = useState(false)

    const handleLoad=(event)=>{
        const img =event.currentTarget
        const isPortrait = img.naturalHeight > img.naturalWidth;
        setIsPortrait(isPortrait)
    }
    return (
        <>
            {!imgError ? (
                <img className={isPortrait ? styles.gameImgLandscape : styles.gameImg} onLoad={handleLoad} src={src} alt={alt} onError={handleImgError}/>
            ) : (
                <div className={styles.gameImg}>{alt}</div>
            )}
        </>
    );
}

function compareKey(key1,key2){
    let key1Array=[]
    let key2Array=[]
    for(const item of key1){
        key1Array.push(item.id)
    }
    key1Array.sort()
    for(const item of key2){
        key2Array.push(item.id)
    }
    key2Array.sort()
    return(JSON.stringify(key1Array) === JSON.stringify(key2Array))
}

const Game = ({id,showMobile,onClose}) => {
    const { jwtToken } = useContext(Context);
    const idProjet = id

    let navigate = useNavigate();

    const nodeRef2 = useRef(null);
    const { network } = useGetNetworkConfig();
    const { address } = useGetAccountInfo();

    const [projectName,setProjectName] = useState()
   
    const [traitKey,setTraitKey] = useState([])
    const [joker,setJoker] = useState([])
    const [jokerKey,setJokerKey] = useState()
    const [closeGame,setCloseGame] = useState(false)

    const [showJokerInfo,setShowJokerInfo] = useState(false)

    const [, setBank] = useState({nft:[],token:[],egld:0})

    const [replacedByPermanentJoker,setReplacedByPermanentJoker]=useState({})
    const [replacedByJokerKey, setReplacedByJokerKey] = useState({})

    const [collectionList,setCollectionList] = useState([])
    const [checkedWallet,setCheckedWallet] = useState(false)

    const [selectJoker, setSelectJoker] = useState(undefined);


    const [showStatTool,setShowStatTool] = useState(false)
    const [showPremiumTool,setShowPremiumTool] = useState(false)
    const [showForbiddenPopup,setShowForbiddenPopup] = useState(false)
    const [score,setScore] = useState()
    //const [totalApproximatePriceContest,setTotalApproximatePriceContest] = useState(0)
    const [,setTotalApproximatePriceGame] = useState(0)

    const [alreadyClaimedNft,setAlreadyClaimedNft] = useState([])
    const [alreadyClaimedTrait,setAlreadyClaimedTrait] = useState([])

    const [validatedContestNft,setValidatedContestNft] = useState([])
    const [validatedContestTrait,setValidatedContestTrait] = useState([])

    const [animationScore,setAnimationScore] = useState(false)

    const [alreadyClaimedRandomJoker,setAlreadyClaimedRandomJoker] = useState(false)
    const [showContestRule,setShowContestRule] = useState(false)

    const [showPopupTresory,setShowPopupTresory] = useState(false)
    const [showScoreBoard,setShowScoreBoard] = useState(false)

    const [frequency,setFrequency] = useState(0)

    //const [lastWinAddress,setLastWinAddress] = useState()

    const [showPopupInfoAdditionalPoint,setShowPopupInfoAdditionalPoint] = useState(false)
    const [showPopupInfoFirst,setShowPopupInfoFirst] = useState(false)

    const [showPopupContestValidation,setShowPopupContestValidation] = useState({display:false})
    const [maxToClaim,setMaxToClaim] = useState(undefined)
    const [claimNb,setClaimNb] = useState(undefined)
    const [showBonusPopup, setShowBonusPopup] = useState(undefined)
    const [position, setPosition] = useState(undefined)
    const [round, setRound] = useState(undefined)
    const [roundMax, setRoundMax] = useState(undefined)
    const [externalLink, setExternalLink] = useState(undefined)
    const [textButtonLink, setTextButtonLink] = useState(undefined)


    const[waitingTrait,setWaitingTrait]=useState([])
    const[waitingNft,setWaitingNft]=useState([])
    const[waitingTraitJoker,setWaitingTraitJoker]=useState([])
    const[waitingNftJoker,setWaitingNftJoker]=useState([])






    useSignTransactions();
    const [, setWs] = useState(null);

    useEffect(() => {
        const newWs = new WebSocket(process.env.REACT_APP_WS);
        setWs(newWs);

        newWs.onopen = () => {
            newWs.send(JSON.stringify({
                type: 'join',
                projectId: idProjet,
            }));
        };

        newWs.onmessage = (event) => {
            setCheckedWallet(false)
            setReplacedByPermanentJoker({})
            setReplacedByJokerKey({})
            fetchData()
        };

        let interval=setInterval(()=>{
            fetchData()
        },5000)

        return () => {
            newWs.close();
            clearInterval(interval)
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idProjet,traitKey]);


    const [seconds, setSeconds] = useState();
    const [secondsContest, setSecondsContest] = useState();


    useEffect(() => {
        const intervalId = setInterval(() => {
            if(seconds!==0){
                setSeconds(seconds - 1);
            }else{
                fetchData()
            }
        }, 1000);
        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seconds]);

    useEffect(()=>{
        if(isMobile){
            navigate(`/game-mobile/${idProjet}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isMobile])

    const [waitingTransaction,setWaitingTransaction]=useState(false);

    

    async function jokerVerification(key, jokerInWallet,alreadyClaimedTrait,alreadyClaimedNft){
        let jokerKeyList={}
        for(const [index,item] of key.entries()){
            item.replaced=false
            if( !item.own ){
                for(const jokerInWalletItem of jokerInWallet){
                    if(jokerInWalletItem.function === "missing attribute"){
                        let tableJoker = jokerKeyList
                        if(tableJoker[index]!==undefined){
                            tableJoker[index].push({nftId:jokerInWalletItem.idCollection,nonce:jokerInWalletItem.nonce})
                        }else{
                            tableJoker[index]=[{nftId:jokerInWalletItem.idCollection,nonce:jokerInWalletItem.nonce}]
                        }
                    }
                    else if(jokerInWalletItem.function === "trait"){
                        if(jokerInWalletItem.replace.nftId === item.nftId && jokerInWalletItem.replace.traitName === item.traitName){
                            let tableJoker = jokerKeyList
                            if(tableJoker[index]!==undefined){
                                tableJoker[index].push({nftId:jokerInWalletItem.idCollection,nonce:jokerInWalletItem.nonce})
                            }else{
                                tableJoker[index]=[{nftId:jokerInWalletItem.idCollection,nonce:jokerInWalletItem.nonce}]
                            }
                        }
                    }
                }
            }
        }
        let contestTrait=[]
        let contestNft=[]
        if(Object.keys(jokerKeyList).length === 1){
            key[Object.keys(jokerKeyList)[0]].replaced=true
            if(key[Object.keys(jokerKeyList)[0]].type==="trait"){
                if(!alreadyClaimedTrait.includes(key[Object.keys(jokerKeyList)[0]].id)){

                    contestTrait.push(key[Object.keys(jokerKeyList)[0]].id)
                }
            }else{
                if(!alreadyClaimedNft.includes(key[Object.keys(jokerKeyList)[0]].id)){
                    contestNft.push(key[Object.keys(jokerKeyList)[0]].id)
                }
            }
            jokerKeyList={}
        }
        setReplacedByJokerKey(jokerKeyList)
        return [key,contestTrait,contestNft,jokerKeyList]
    }

    function assignJoker(indexKey){
        try{
            setSelectJoker(undefined)
        
            let contestTraitJoker =[...waitingTraitJoker]
            let contestNftJoker =[...waitingNftJoker]

            if(traitKey[indexKey].type==="trait"){
                contestTraitJoker.push(traitKey[indexKey].id)
            }else{
                contestNftJoker.push(traitKey[indexKey].id)
            }
            if(alreadyClaimedNft.length===0 || alreadyClaimedNft.length===0){

                setShowPopupContestValidation({display:true,contestTrait:waitingTrait,contestNft:waitingNft,contestTraitJoker,contestNftJoker})
                

            }else{
                contestValidation([],[],contestTraitJoker,contestNftJoker)

            }
            let newTraitkey = JSON.parse(JSON.stringify(traitKey));
            newTraitkey[indexKey].replaced=true
            setTraitKey(newTraitkey)

            setReplacedByJokerKey({})
        }catch(e){
            console.log(e)
        }
    }


    function clickToAssignJoker(indexKey){
        assignJoker(indexKey)
    }



    const fetchData = ()=>{
        axios.get(process.env.REACT_APP_API_URL+"/get_key?idProjet="+idProjet+"&address="+address).then((response)=>{
            setFrequency(response.data.generationFrequency)
            setProjectName(response.data.projectName)
            //setLastWinAddress(response.data.contestInformation.lastToWin)
            setMaxToClaim(response.data.contestInformation.maxToClaim)
            setClaimNb(response.data.contestInformation.claimNb)
            setPosition(response.data.contestInformation.position)
            setRound(response.data.contestInformation.round + 1)
            setRoundMax(response.data.contestInformation.roundMax)
            setExternalLink(response.data.externalLink)
            setTextButtonLink(response.data.textButtonLink)
            
            if(!response.data.active || !response.data.contestInformation || response.data.contestInformation.remainingTimeContest <= 0 || (response.data.contestInformation.round >= response.data.contestInformation.roundMax)){
                
                setCloseGame(true)
            }else{
                setBank(response.data.toWin);
                if(!compareKey(traitKey,response.data.key)){
                    setCheckedWallet(false)
                    setTraitKey(response.data.key);
                    setWaitingTrait([])
                    setWaitingNft([])
                    setWaitingTraitJoker([])
                    setWaitingNftJoker([])
                    setShowPopupContestValidation({display:false})
                }
                
                setJoker(response.data.joker)
    
                let collectionListArray = [];
    
                for(const item of response.data.key){
                    if(!collectionListArray.includes(item.nftId)) collectionListArray.push(item.nftId)
                }
                for(const joker of response.data.joker){
                    if(joker.type==="key"){
                        setJokerKey(joker)
                    }
                    if(!collectionListArray.includes(joker.nftId)) collectionListArray.push(joker.nftId)
                }
                setCollectionList(collectionListArray)
                setSeconds(response.data.remainingTime)
                setSecondsContest(response.data.contestInformation.remainingTimeContest)

                setTotalApproximatePriceGame(response.data.totalApproximatePrice)
                //setTotalApproximatePriceContest(response.data.contestInformation.totalApproximatePrice)

                setAlreadyClaimedTrait(response.data.contestInformation.alreadyClaimedTrait)
                setAlreadyClaimedNft(response.data.contestInformation.alreadyClaimedNft)

                setAlreadyClaimedRandomJoker(response.data.contestInformation.alreadyClaimedRandomJoker)

            }
            
        })
    }

    const fetchScore = async () => {
        try{
            let response = await axios.get(process.env.REACT_APP_API_URL+"/get_individual_score?projectId="+idProjet,{headers: { Authorization: `Bearer ${jwtToken}` }})
            setScore(response.data.score)
        }catch(e){
            console.log(e)
        }

    }

    useEffect(()=>{
        if(address){
            fetchData();              

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[address])

    useEffect(()=>{
        if(jwtToken){
            fetchScore();  
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[jwtToken])



    const checkWallet = async(alreadyUsedRandomJoker) => {
        const result = await axios.get(`${network.apiAddress}/accounts/${address}/nfts?size=10000&collections=${encodeURIComponent(collectionList.join(","))}`)
        let key = [...traitKey]
        
        let allJokerInWallet=[]

        let contestNft=[]
        let contestTrait=[]
        
        for(const nft of result.data){
            let functionJoker=undefined;
            
            //Check if can be used as a joker
            for(const item of joker){

                if(item.format === "nft" && item.nftId===nft.collection && parseInt(item.nonce)===nft.nonce){
                    functionJoker=item.function
                    let jokerInWallet={type:item.type,idCollection : item.nftId, nonce : item.nonce, function : functionJoker,replace: item.replace ? {traitName:item.replace.traitName,nftId:item.replace.gameCollection} : undefined}
                    allJokerInWallet.push(jokerInWallet)
                }else if(item.format==="trait" && item.nftId===nft.collection){
                    if(item.attributeOnChain){
                        const attributesUTF8 = base64ToUtf8(nft.attributes).split(";");
                        for (const attributes of attributesUTF8){
                            let trait = attributes.split(":")
                            let traitName = trait[0]
                            let traitValue = trait[1]
                            
                            if(item.traitName === traitName && item.traitValue === traitValue){
                                functionJoker=item.function
                                let jokerInWallet={type:item.type, idCollection : item.nftId, nonce : nft.nonce, function : functionJoker,replace: item.replace ? {traitName:item.replace.traitName,nftId:item.replace.gameCollection} : undefined}
                                allJokerInWallet.push(jokerInWallet)
                            }
                        }
                    }else{
                        for(const attribute of nft.metadata.attributes){
                            if(item.traitName === attribute.trait_type && item.traitValue === attribute.value){
                                functionJoker=item.function
                                let jokerInWallet={type:item.type, idCollection : item.nftId, nonce : item.nonce, function : functionJoker,replace: item.replace ? {traitName:item.replace.traitName,nftId:item.replace.gameCollection} : undefined}
                                allJokerInWallet.push(jokerInWallet)
                            }
                        }
                    }

                }
            }
            for(const item of key){
                if(item.type==="nft" && alreadyClaimedNft.includes(item.id)){
                    item.own = true
                    continue
                }
                if(item.type==="trait" && alreadyClaimedTrait.includes(item.id)){
                    item.own = true
                    continue
                }
                if(item.type==="nft" && item.nftId===nft.collection && parseInt(item.nonce)===nft.nonce){
                    contestNft.push(item.id)
                    item.own = true
                    
                }else if(item.type==="trait" && item.nftId===nft.collection){
                    if(item.attributeOnChain){

                        const attributesUTF8 = base64ToUtf8(nft.attributes).split(";");
                        for (const attributes of attributesUTF8){
                            let trait = attributes.split(":")
                            let traitName = trait[0]
                            let traitValue = trait[1]


                            if(item.traitName===traitName && item.traitValue === traitValue){
                                item.own = true
                                contestTrait.push(item.id)
                            }
                        }
                    }else{
                        for(const attribute of nft.metadata.attributes){
                            if(item.traitName===attribute.trait_type && item.traitValue === attribute.value){
                                item.own = true
                                contestTrait.push(item.id)
                            }
                        }
                    }

                }
            }
        }

        let contestTraitJoker=[]
        let contestNftJoker=[]
        let jokerKeyList={}
        if(!alreadyUsedRandomJoker){
            let response = await jokerVerification(key,allJokerInWallet,alreadyClaimedTrait,alreadyClaimedNft)

            key=response[0]
            contestTraitJoker=response[1]
            contestNftJoker=response[2]
            jokerKeyList=response[3]
        }
        setCheckedWallet(true)

        setTraitKey(key)

        

        if((contestTrait.length>0 || contestNft.length>0 || contestTraitJoker.length>0 || contestNftJoker.length>0) && ((alreadyClaimedNft.length!==0 || alreadyClaimedTrait.length!==0) || maxToClaim===undefined)){
            contestValidation(contestTrait,contestNft,contestTraitJoker,contestNftJoker)
        }else if((contestTrait.length>0 || contestNft.length>0 || contestTraitJoker.length>0 || contestNftJoker.length>0) && Object.keys(jokerKeyList).length===0 && Object.keys(replacedByPermanentJoker).length===0 ){
            setTimeout(()=>setShowPopupContestValidation({display:true,contestTrait,contestNft,contestTraitJoker,contestNftJoker}),500)
        }else if((contestTrait.length>0 || contestNft.length>0 || contestTraitJoker.length>0 || contestNftJoker.length>0) && (Object.keys(jokerKeyList).length!==0 || Object.keys(replacedByPermanentJoker).length!==0 )){
            setWaitingTrait(contestTrait)
            setWaitingNft(contestNft)
            setWaitingTraitJoker(contestTraitJoker)
            setWaitingNftJoker(contestNftJoker)
            setTimeout(()=>window.alert("Please, choose joker"),1000)
        }

    }

    const contestValidation = async (contestTrait,contestNft,contestTraitJoker,contestNftJoker) =>{
        try{
            let response = await axios.post(`${process.env.REACT_APP_API_URL}/contest_validation`,
            {projectId:parseInt(id),key:{nft:contestNft,trait:contestTrait,nftJoker:contestNftJoker,traitJoker:contestTraitJoker}},
            {headers: { Authorization: `Bearer ${jwtToken}` }})

            let tableIdTrait=[...validatedContestTrait]
            for(const item of response.data.trait){
                tableIdTrait.push(item.id)
            }
            

            setValidatedContestTrait(tableIdTrait)

            let tableIdNft=[...validatedContestNft]
            for(const item of response.data.nft){
                tableIdNft.push(item.id)
            }
            setValidatedContestNft(tableIdNft)

            setAnimationScore(true)

            setShowBonusPopup(response.data.bonusPoint > 0 ? response.data.bonusPoint : undefined)

            let timeInterval = 2000/response.data.totalPointNumber
            timeInterval = timeInterval>100 ? 100 : timeInterval
        
            const interval = setInterval(() => {
                let newScore=score + response.data.totalPointNumber
                setScore(prevCount => {
                  if (prevCount < newScore) {
                    return prevCount + 1;
                  } else {
                    clearInterval(interval);
                    setAnimationScore(false)
                    return prevCount;
                  }
                });
            }, timeInterval);
            let complete = response.data.complete
            let change = response.data.change

            setWaitingNft([])
            setWaitingTrait([])
            setWaitingNftJoker([])
            setWaitingTraitJoker([])
            if(complete && !change){
                setTimeout(()=>setShowPopupInfoFirst(true),timeInterval)
            }
        }catch(e){
            console.log("Error contest validation")
        }
    }

    const hours = Math.floor(seconds /  3600);
    const minutes = Math.floor((seconds % (3600))/60);
    const remainingSeconds = seconds % (60);


    const daysContest = Math.floor(secondsContest / 86400)
    const hoursContest = Math.floor((secondsContest % 86400) /  3600);
    const minutesContest = Math.floor(((secondsContest % 86400) %  3600)/60);

    let traitComponents =
            traitKey.map((trait,index)=>{
                let toWrite = trait.type === "trait" ? `${trait.traitName.toUpperCase()} - ${trait.traitValue}` : `${trait.nftId}-${nonceToHexadecimal(parseInt(trait.nonce))}`
                if(trait.own || trait.replaced){
                    return <Trait key={index} orientation={"right"}>{toWrite}</Trait>
                }else if (checkedWallet){
                    if(Object.keys(replacedByJokerKey).includes(index.toString()) || Object.keys(replacedByPermanentJoker).includes(index.toString())){
                        return <Trait onClick={()=>assignJoker(index)} key={index} orientation={"center-joker"}>{toWrite}</Trait>
                    }else{
                        return <Trait marketplace={trait.type==="trait" ? `https://www.frameit.gg/marketplace/${trait.nftId}/items?sp=true&filterBy=All&sortBy=RankAsc&activeFilters=[{"trait":"${trait.traitName}","attribute":"${trait.traitValue}"}]`:`https://www.frameit.gg/marketplace/nft/${trait.nftId}-${nonceToHexadecimal(parseInt(trait.nonce))}`} key={index} orientation={"left"}>{toWrite}</Trait>
                    }
                }else{
                    return <Trait key={index} orientation={"center"}>{toWrite}</Trait>
                }
            })
       
    return(
        <>
        {!closeGame&&<PopupDisclaimer />}
        {showPopupTresory && <PopupTresory idProjet={id} onClose={()=>setShowPopupTresory(false)}/>}
        {showScoreBoard && <ScoreBoard idProjet={id} onClose={()=>setShowScoreBoard(false)} address={address}/>}
        {showPopupInfoAdditionalPoint && <PopupInfoAdditionalPoint onClose={()=>setShowPopupInfoAdditionalPoint(false)}/>}
        {showPopupInfoFirst && <PopupInfoFirst onClose={()=>setShowPopupInfoFirst(false)}/>}
        {showPopupContestValidation.display && <PopupContestValidation onValidation={()=>contestValidation(showPopupContestValidation.contestTrait,showPopupContestValidation.contestNft,showPopupContestValidation.contestTraitJoker,showPopupContestValidation.contestNftJoker)} maxToClaim={maxToClaim} claimNb={claimNb} onClose={()=>setShowPopupContestValidation({display:false})} position={position} traitKey={traitKey}/>}
        {showBonusPopup && <PopupBonus onClose={()=>setShowBonusPopup(undefined)} pointNumber={showBonusPopup}/>}

        {closeGame && <Card background={projectName ? `${process.env.REACT_APP_API_URL}/${projectName}/background.png` : undefined}><div className={styles.game}><ScoreBoard notPopup={true} title={"Result"} idProjet={id} onClose={()=>onClose()} address={address}/></div></Card>}

        {!closeGame && <Card isMobile={showMobile} background={projectName ? `${process.env.REACT_APP_API_URL}/${projectName}/background.png` : undefined}>

            {waitingTransaction && <SignTransactionAlert onClose={()=>{setWaitingTransaction(false)}}/>}
            {showForbiddenPopup && <ForbiddenPopup onClose={()=>{setShowForbiddenPopup(false)}}/>}
            {selectJoker !== undefined && <PopupSelectJoker onClose={()=>setSelectJoker(undefined)} keyIndex={selectJoker} action={assignJoker} randomJoker={replacedByJokerKey.length!==0 ?replacedByJokerKey[selectJoker]:[{nftId:"jknlp",nonce:"kjl"}]} permanentJoker={replacedByPermanentJoker.length!==0 ?replacedByPermanentJoker[selectJoker]:[]}/>}
            <div className={styles.game}>
                {showContestRule && <ContestRule keyNumber={traitKey.length} keyTime={frequency} nameCollection={projectName} openTresory={()=>setShowPopupTresory(true)} openListTrait={()=>setShowStatTool(true)} onClose={()=>setShowContestRule(false)}/>}

                {(!showStatTool && !showPremiumTool) && <>
                    <img alt="" className={styles.arrow} src={arrowImg} onClick={(onClose)}/>

                    <div className={styles.header}>
                        <div className={styles.buttonStats}>
                            <ButtonDesigned style={{cursor:"unset"}} className={styles.contestInfo}>
                                <table>
                                        <tr><td>Next<span className={styles.spanHide}> round</span></td><td>{seconds>=0 && <><span>{daysContest}</span>D <span>{hoursContest}</span>H <span>{minutesContest}</span>M</>}</td></tr>
                                        <tr><td>Round</td><td>{round}/{roundMax}</td></tr>
                                        <tr><td>Score</td><td><span className={animationScore && styles.scoreIncreasing}>{score}</span> point{score !== 0 && "s"}</td></tr>

                                </table>
                            </ButtonDesigned>
                            {/* <ButtonDesigned onClick={()=>setShowStatTool(true)}>Game tools</ButtonDesigned><ButtonDesigned onClick={()=>setShowPremiumTool(true)}>Premium tools</ButtonDesigned> */}
                        </div>
                        <div>
                            <h2>The Ants Game - Beta</h2>
                            {<ButtonDesigned onClick={()=>checkWallet(alreadyClaimedRandomJoker)} className={styles.buttonCheckWallet }>{`Check my wallet ` + (claimNb!==undefined ? `${claimNb}/${maxToClaim}` : `0/14`)}</ButtonDesigned> }
                        </div>
                        <div className={styles.leftPart}><img alt="" src={`${process.env.REACT_APP_API_URL}/${projectName}/logo.png`}/>{externalLink!==undefined && <ButtonDesigned onClick={()=>window.open(externalLink,"_blank")}>{textButtonLink}</ButtonDesigned>}</div> 
                    </div>

                    <div className={styles.traitGallery}>
                        {traitKey.map(trait => 
                            <div className={styles.frameKey} title={`${trait.traitName} ${trait.traitValue}`}>
                                <img alt="" className={styles.frameImg} src={frame}/>
                                <GameImage  src={`${process.env.REACT_APP_API_URL+trait.urlImage}`} alt={trait.type!=="nft" ? `${trait.traitName} ${trait.traitValue}` : `${trait.nftId}-${nonceToHexadecimal(parseInt(trait.nonce))}`}/>
                                <div className={
                                        styles.score + " " +
                                        (((trait.type==="trait" && validatedContestTrait.includes(trait.id)) || (trait.nft==="nft" && validatedContestNft.includes(trait.id))) ? styles.greenAnimation : "")
                                    }>
                                    {trait.pointNumber}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={styles.bottom}>
                        
                        <div className={styles.cardTrait}>
                            
                            {traitComponents}
                            
                        </div>
                        <div className={styles.clickable}>
                            <p>Click for info</p>
                            <div className={styles.buttons}>
                                <ButtonDesigned style={{padding:"5px"}} onClick={()=>setShowStatTool(true)}>Game tools</ButtonDesigned>
                                <ButtonDesigned style={{padding:"5px"}} onClick={()=>setShowScoreBoard(true)}>Ranking</ButtonDesigned>
                                <ButtonDesigned style={{padding:"5px"}} onClick={()=>setShowPopupTresory(true)}>Ranking gain</ButtonDesigned>
                                <ButtonDesigned style={{padding:"5px"}} onClick={()=>setShowPopupInfoAdditionalPoint(true)}>Bonus points</ButtonDesigned>
                            </div>
                            

{/*                             <div className={styles.frameKey} onClick={showTresory ? ()=>setShowTresory(false) : ()=>setShowTresory(true)}>
 */}{/*                                 <img className={styles.frameImg} src={frame}/>
 */}                                {/* <img alt="" className={styles.gameImg} src={tresory2}/>
                                    <div  className={styles.infoTresory}>
                                        <div onClick={()=>setShowPopupTresory(true)} className={styles.amountTresory}>
                                            <div>Contest</div>
                                            <div><span className={styles.spanHide}>Value </span>{Math.floor(totalApproximatePriceContest*100)/100} EGLD</div>
                                        </div>
                                        <div onClick={()=>setShowPopupInfoAdditionalPoint(true)} className={styles.amountTresory}>
                                            <div>Full Key</div>
                                            <div>+ 5 points</div>
                                        </div>
                                       
                                    </div> */}
{/*                                 <img className={styles.gameImg} src={tresory}/>
 */}{/*                                 <div className={styles.title}>Pool</div>
 */}                                {/* <Transition nodeRef={nodeRef} in={showTresory} timeout={duration}>
                                    {state => (
                                        <div className={styles.popupInfoContainerLeft} onClick={(event)=>event.stopPropagation()}>
                                            <div className={styles.popupInfo} ref={nodeRef} style={{
                                                ...defaultStyleLeft,
                                                ...transitionStylesLeft[state]
                                                }}>
                                                {
                                                    bank.nft.map((toWin)=>
                                                        <div>{`${toWin.quantity} NFT ${toWin.collectionId}-${nonceToHexadecimal(toWin.nonce)}`}</div>
                                                    )
                                                }{
                                                    bank.token.map((toWin)=>
                                                        <>
                                                        <div>{`${Math.floor(toWin.quantity*100)/100} token ${toWin.tokenId}`}</div>
                                                        </>
                                                    )
                                                }{
                                                    <div>{bank.egld !== 0 && `${Math.floor(bank.egld*100)/100} EGLD`}</div>
                                                }
                                            </div>
                                        </div>
                                    )}
                                </Transition> */}
                            {/* </div> */}
                        </div>
                        <div>
                            <ButtonDesigned onClick={()=>setShowContestRule(true)}>Game rules</ButtonDesigned>
                            <div className={styles.frameKey} onClick={showJokerInfo ? ()=>setShowJokerInfo(false) : ()=>setShowJokerInfo(true)}>
                            {jokerKey && <>
                                <img alt="" className={styles.frameImg + " "+styles.joker} src={frame}/>
                                <GameImage src={jokerKey !== undefined && `${process.env.REACT_APP_API_URL+jokerKey.url}`} alt={jokerKey !== undefined && (jokerKey.format === "nft" ? `${jokerKey.nftId}-${nonceToHexadecimal(parseInt(jokerKey.nonce))}` : `${jokerKey.traitName} ${jokerKey.traitValue}`)}/>
                                <div className={styles.titleLeft}><a el="noreferrer" rel="noreferrer" target="_blank" href={jokerKey.format==="trait" ? `https://www.frameit.gg/marketplace/${jokerKey.nftId}/items?sp=true&filterBy=All&sortBy=RankAsc&activeFilters=[{"trait":"${jokerKey.traitName}","attribute":"${jokerKey.traitValue}"}]`:`https://www.frameit.gg/marketplace/nft/${jokerKey.nftId}-${nonceToHexadecimal(parseInt(jokerKey.nonce))}`}><img alt="" className={""} src={frameIt}/></a>Joker</div>
                                <Transition nodeRef={nodeRef2} in={showJokerInfo} timeout={duration}>
                                    {state => (
                                        <div className={styles.popupInfoContainerRight} onClick={(event)=>event.stopPropagation()}>
                                            <div className={styles.popupInfo} ref={nodeRef2} style={{
                                                ...defaultStyle,
                                                ...transitionStyles[state]
                                                }}>
                                                This attribute automatically validates a {jokerKey !== undefined && (jokerKey.function==="missing attribute" ? "missing attribute" : `${jokerKey.replace.traitName} trait`)}
                                            </div>
                                        </div>
                                    )}
                                </Transition>
                            </>}
                            </div>
                        </div>
                        
                    </div>
                </>}
                {
                    showStatTool && <GameTool contest={true} openPremium={()=>setShowPremiumTool(true)} onClose={()=>setShowStatTool(false)} idProject={idProjet}/>
                }
                {
                    showPremiumTool && <GameToolPremium onClose={()=>setShowPremiumTool(false)} redirectMint={onClose}/>
                }
                
            </div>
            {!showContestRule && !showStatTool && !showPremiumTool && <div className={styles.timer + ` ${isMobile && styles.timerMobile}`}>{seconds>=0 && <><span>{hours}</span>h <span>{minutes}</span>m <span>{remainingSeconds}</span>s</>}</div>}
        </Card>}
        </>
    )
}

export default Game;