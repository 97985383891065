// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ButtonDesignedVar2_button__3hTRX {\n  box-sizing: border-box;\n  width: 180px;\n  height: 50px;\n  background: #3C305C;\n  box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.75);\n  border-radius: 10px;\n  border: none;\n  font-family: \"Bangers\";\n  font-size: 30px;\n  color: white;\n  cursor: pointer;\n}\n\n.ButtonDesignedVar2_selected__1YpVF {\n  border: 2px solid #0C09B2;\n}", "",{"version":3,"sources":["webpack://src/components/ButtonDesignedVar2/ButtonDesignedVar2.module.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,YAAA;EACA,YAAA;EACA,mBAAA;EACA,+CAAA;EACA,mBAAA;EACA,YAAA;EACA,sBAAA;EACA,eAAA;EACA,YAAA;EACA,eAAA;AACJ;;AAIA;EACI,yBAAA;AADJ","sourcesContent":[".button{\n    box-sizing: border-box;\n    width: 180px;\n    height: 50px; \n    background: #3C305C;\n    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.75);\n    border-radius: 10px;\n    border: none;\n    font-family: \"Bangers\";\n    font-size: 30px;\n    color: white;\n    cursor:pointer;\n\n    \n}\n\n.selected{\n    border: 2px solid #0C09B2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "ButtonDesignedVar2_button__3hTRX",
	"selected": "ButtonDesignedVar2_selected__1YpVF"
};
export default ___CSS_LOADER_EXPORT___;
