import {useEffect,useState} from "react"
import styles from "./MenuMobile.module.scss"
import Button from "../ButtonDesignedVar2"
import EditProfile from "../EditProfile"
import Inventory from "../Inventory"
import {useNavigate} from 'react-router-dom';
import {logout} from '@multiversx/sdk-dapp/utils';




const MenuMobile = (props) => {

    let navigate = useNavigate();


    const [section,setSection] = useState(props.section ?? "menu")
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return ()=> document.body.style.overflow = 'unset';
     }, []);


    

    return(
        <>
        {section === "menu" &&
        <div className={styles.menu}>
            <Button onClick={()=>setSection("inventory")}>Inventory</Button>
            <Button onClick={()=>setSection("edit")}>Edit Profile</Button>
            <Button onClick={()=>{props.onClose();navigate("/")}}>Home</Button>
            <Button onClick={()=>{props.onClose();navigate("/dao")}}>Dao</Button>
            <Button onClick={logout}>Logout</Button>
        </div>
        }
        {section === "edit" &&
        <div className={styles.edit}>
            <>
            <Button selected={true} onClick={props.onClose}>Edit Profile</Button>
            <EditProfile user={props.user} facebook={props.facebook} discord={props.discord} twitter={props.twitter} mail={props.mail} setUser={props.setUser} setFacebook={props.setFacebook} setDiscord={props.setDiscord} setTwitter={props.setTwitter} setMail={props.setMail}/>
            </>
        </div>
        }
        {section === "inventory" &&
        <div className={styles.edit}>
            <>
            <Button selected={true} onClick={props.onClose}>Inventory</Button>
            <Inventory updateProfilePicture={props.onClose}/>
            </>
        </div>
        }
        </>
    )
}

export default MenuMobile;