import {useEffect, useState,useContext} from "react"
import styles from "./Game.module.scss"
import Card from "../Card"
import PopupSelectJoker from "../PopupSelectJoker"
import networkImg from "../../assets/img/network-img.png"
import ButtonDesigned from "../ButtonDesigned"
import frame from "../../assets/img/frame.png"
import tresory2 from "../../assets/img/tresory2.png"

import Trait from "../Trait"
import axios from 'axios'
import { useGetNetworkConfig,useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import {isMobile} from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import {Context} from '../../ContextProvider'
import { Transition } from 'react-transition-group';
import { useRef } from 'react';
import {useSignTransactions} from '@multiversx/sdk-dapp/hooks/transactions';
import {refreshAccount} from '@multiversx/sdk-dapp/utils/account';
import {sendTransactions} from '@multiversx/sdk-dapp/services'
import SignTransactionAlert from "../SignTransactionAlert";
import {nonceToHexadecimal} from "../../utils"
import GameTool from '../GameTool'
import GameToolPremium from '../GameToolPremium'

import arrowImg from "../../assets/img/arrow-left.svg"
import ForbiddenPopup from "../ForbiddenAlert"


const duration = 300;

const defaultStyle = {
  transition: `left ${duration}ms ease-in-out`,
  left:'220px'
}

const transitionStyles = {
  entering: {left:'0px' },
  entered:  { left:'20px' },
  exiting:  {left:'0px'},
  exited:  { left:'220px' },
};

function base64ToUtf8(base64Str) {
    // Utilisez la méthode `atob()` pour décoder la chaîne base64 en une chaîne binaire
    const binaryStr = atob(base64Str);
    // Créez un tableau de codes UTF-8 à partir de la chaîne binaire
    const utf8Codes = new Uint8Array(binaryStr.length);
    for (let i = 0; i < binaryStr.length; i++) {
      utf8Codes[i] = binaryStr.charCodeAt(i);
    }
    // Utilisez la méthode `TextDecoder` pour décoder les codes UTF-8 en une chaîne UTF-8
    const utf8Decoder = new TextDecoder();
    const utf8Str = utf8Decoder.decode(utf8Codes);
    return utf8Str;
  }
  
function GameImage ({src,alt}) {
    const [imgError, setImgError] = useState(false);
    const handleImgError = () => {
        if (!imgError) {
            setImgError(true);
        }
    };

    const [isPortrait, setIsPortrait] = useState(false)

    const handleLoad=(event)=>{
        const img =event.currentTarget
        const isPortrait = img.naturalHeight > img.naturalWidth;
        setIsPortrait(isPortrait)
    }
    return (
        <>
            {!imgError ? (
                <img className={isPortrait ? styles.gameImgLandscape : styles.gameImg} onLoad={handleLoad} src={src} alt={alt} onError={handleImgError}/>
            ) : (
                <div className={styles.gameImg}>{alt}</div>
            )}
        </>
    );
}

function compareKey(key1,key2){
    let key1Array=[]
    let key2Array=[]
    for(const item of key1){
        key1Array.push(item.id)
    }
    key1Array.sort()
    for(const item of key2){
        key2Array.push(item.id)
    }
    key2Array.sort()
    return(JSON.stringify(key1Array) === JSON.stringify(key2Array))
}

const Game = ({id,showMobile,onClose}) => {
    //To do : gérer cas quand contest terminé ou plus d'argent dans le trésor 
    const { jwtToken } = useContext(Context);
    const idProjet = id

    let navigate = useNavigate();

    const nodeRef2 = useRef(null);
    const { network } = useGetNetworkConfig();
    const { address } = useGetAccountInfo();

    const [projectName,setProjectName] = useState()
   
    const [traitKey,setTraitKey] = useState([])
    const [joker,setJoker] = useState([])
    const [jokerKey,setJokerKey] = useState()
    const [closeGame,setCloseGame] = useState(false)

    const [showJokerInfo,setShowJokerInfo] = useState(false)

    const [bank, setBank] = useState({nft:[],token:[],egld:0})

    const [replacedByPermanentJoker,setReplacedByPermanentJoker]=useState({})
    const [replacedByJokerKey, setReplacedByJokerKey] = useState({})

    const [collectionList,setCollectionList] = useState([])
    const [checkedWallet,setCheckedWallet] = useState(false)
    const [, setSessionId] = useState(null);

    const [selectJoker, setSelectJoker] = useState(undefined);

    const [showKeyValidation,setShowKeyValidation] = useState(false)

    const [showStatTool,setShowStatTool] = useState(false)
    const [showPremiumTool,setShowPremiumTool] = useState(false)
    const [forbiddenToClaim,setForbiddenAddresses] = useState([])
    const [showForbiddenPopup,setShowForbiddenPopup] = useState(false)
    //const [score,setScore] = useState()
    //const [totalApproximatePriceContest,setTotalApproximatePriceContest] = useState(0)
    const [totalApproximatePriceGame,setTotalApproximatePriceGame] = useState(0)

    //const [alreadyClaimedNft,setAlreadyClaimedNft] = useState([])
    const [alreadyClaimedTrait,setAlreadyClaimedTrait] = useState([])

    //const [validatedContestNft,setValidatedContestNft] = useState([])
    //const [validatedContestTrait,setValidatedContestTrait] = useState([])

    //const [animationScore,setAnimationScore] = useState(false)

    const [showBank,setShowBank] = useState(false)



    const fetchData = ()=>{
        axios.get(process.env.REACT_APP_API_URL+"/get_key?idProjet="+idProjet+"&address="+address).then((response)=>{
            setProjectName(response.data.projectName)
            if((!response.data.active) || (response.data.toWin["nft"].length === 0 && response.data.toWin["token"].length === 0 && response.data.toWin["egld"]===0 && !response.data.contestInformation)){
                setCloseGame(true)
            }else{
                setBank(response.data.toWin);
                setForbiddenAddresses(response.data.forbiddenToClaim)
                if(!compareKey(traitKey,response.data.key)){
                    setCheckedWallet(false)
                    setTraitKey(response.data.key);
                }
                
                setJoker(response.data.joker)
    
                let collectionListArray = [];
    
                for(const item of response.data.key){
                    if(!collectionListArray.includes(item.nftId)) collectionListArray.push(item.nftId)
                }
                for(const joker of response.data.joker){
                    if(joker.type==="key"){
                        setJokerKey(joker)
                    }
                    if(!collectionListArray.includes(joker.nftId)) collectionListArray.push(joker.nftId)
                }
                setCollectionList(collectionListArray)
                setSeconds(response.data.remainingTime)
                //setSecondsContest(response.data.contestInformation.remainingTimeContest)

                setTotalApproximatePriceGame(response.data.totalApproximatePrice)
                //setTotalApproximatePriceContest(response.data.contestInformation.totalApproximatePrice)

                setAlreadyClaimedTrait(response.data.contestInformation.alreadyClaimedTrait)
                //setAlreadyClaimedNft(response.data.contestInformation.alreadyClaimedNft)



            }
            
        })
    }


    useSignTransactions();
    const [, setWs] = useState(null);

    useEffect(() => {
        // Créer un nouveau WebSocket lorsqu'on monte le composant
        const newWs = new WebSocket(process.env.REACT_APP_WS);
        setWs(newWs);

        // Rejoindre la salle de chat
        newWs.onopen = () => {
            newWs.send(JSON.stringify({
                type: 'join',
                projectId: idProjet,
            }));
        };

        // Gérer les messages reçus
        newWs.onmessage = (event) => {
            setCheckedWallet(false)
            setReplacedByPermanentJoker({})
            setReplacedByJokerKey({})
            fetchData()
        };

        //A supprimer lorsque l'appli sera déployée sur un serveur qui accepte le websocket
        let interval=setInterval(()=>{
            fetchData()
        },5000)

        // Se déconnecter proprement lorsque le composant est démonté
        return () => {
            newWs.close();
            clearInterval(interval)
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [idProjet,traitKey]);


    //const traitKey = [{type:"nft",id:3,urlImage:`${process.env.REACT_APP_API_URL}/2-idProjet/trait/4-idTrait/1.png`,nftId:"",traitName:"kk",traitValue:"vfd",nftNonce:""}]
    const [seconds, setSeconds] = useState();
    //const [secondsContest, setSecondsContest] = useState();


    useEffect(() => {
        const intervalId = setInterval(() => {
            if(seconds!==0){
                setSeconds(seconds - 1);
            }else{
                fetchData()
            }
        }, 1000);
        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [seconds]);

    useEffect(()=>{
        if(isMobile){
            navigate(`/game-mobile/${idProjet}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isMobile,idProjet])

    const [waitingTransaction,setWaitingTransaction]=useState(false);

    const getRewardsTransaction = async(data) => {
        if(!waitingTransaction){
            const contractAddress = process.env.REACT_APP_REWARDS_CONTRACT

            const transaction = {
                value: "0",
                data: `withdraw@${data}`,
                receiver: contractAddress,
                gasLimit: 50_000_000,
            };
            await refreshAccount();
            const { sessionId,  } = await sendTransactions({
                transactions: transaction,
                transactionsDisplayInfo:{
                    processingMessage: 'Processing transaction',
                    errorMessage: 'An error has occured during transaction',
                    successMessage: 'Success'
                },
                redirectAfterSign: false
            });
            if (sessionId != null){
                setSessionId(sessionId);
                setWaitingTransaction(true);
            }
        }
        
    }
/*     const transactionStatus = transactionServices.useTrackTransactionStatus({
        transactionId: sessionId,
        onFail:()=>{setWaitingTransaction(false)},
        onCancelled:()=>{setWaitingTransaction(false)},
        onSuccess:()=>{setWaitingTransaction(false)}

    });
 */
    async function jokerVerification(key, jokerInWallet){
        let jokerPermanent={}
        let jokerKeyList={}
        for(const [index,item] of key.entries()){
            item.replaced=false
            if(!item.own ){
                for(const jokerInWalletItem of jokerInWallet){
                    if(jokerInWalletItem.function === "missing attribute"){
                        let tableJoker = jokerInWalletItem.type === "permanent" ? jokerPermanent : jokerKeyList
                        if(tableJoker[index]!==undefined){
                            tableJoker[index].push({nftId:jokerInWalletItem.idCollection,nonce:jokerInWalletItem.nonce})
                        }else{
                            tableJoker[index]=[{nftId:jokerInWalletItem.idCollection,nonce:jokerInWalletItem.nonce}]
                        }
                    }
                    else if(jokerInWalletItem.function === "trait"){
                        //If correspondfs to criters can be ass as joker for the key
                        if(jokerInWalletItem.replace.nftId === item.nftId && jokerInWalletItem.replace.traitName === item.traitName){
                            let tableJoker = jokerInWalletItem.type === "permanent" ? jokerPermanent : jokerKeyList
                            if(tableJoker[index]!==undefined){
                                tableJoker[index].push({nftId:jokerInWalletItem.idCollection,nonce:jokerInWalletItem.nonce})
                            }else{
                                tableJoker[index]=[{nftId:jokerInWalletItem.idCollection,nonce:jokerInWalletItem.nonce}]
                            }
                        }
                    }

                }
            }

        }
        if(Object.keys(jokerKeyList).length === 1){
            key[Object.keys(jokerKeyList)[0]].replaced=true
            delete jokerPermanent[Object.keys(jokerKeyList)[0]]
            jokerKeyList={}
        }
        if(Object.keys(jokerPermanent).length === 1){
            key[Object.keys(jokerPermanent)[0]].replaced=true
            delete jokerKeyList[Object.keys(jokerPermanent)[0]]
            jokerPermanent={}
        }
        if(Object.keys(jokerKeyList).length === 1){
            key[Object.keys(jokerKeyList)[0]].replaced=true
            delete jokerPermanent[Object.keys(jokerKeyList)[0]]
            jokerKeyList={}
        }
        if(Object.keys(jokerPermanent).length === 2 && Object.keys(jokerKeyList).length === 2){
            let i=0
            for(const j of Object.keys(jokerKeyList)){
                for(const k of Object.keys(jokerKeyList)){
                    if(k===j){
                        i++
                    }
                }
            }
            if(i===2){
                key[Object.keys(jokerPermanent)[0]].replaced=true
                key[Object.keys(jokerPermanent)[1]].replaced=true
                jokerPermanent={}
                jokerKeyList={}
            }
        }
        setReplacedByJokerKey(jokerKeyList)
        setReplacedByPermanentJoker(jokerPermanent)
        return(key)
    }

    function assignJoker(indexKey,type){
        setSelectJoker(undefined)
        
        let newTraitkey = JSON.parse(JSON.stringify(traitKey));
        newTraitkey[indexKey].replaced=true
        setTraitKey(newTraitkey)


        if(type === "permanent"){
            setReplacedByPermanentJoker({})
            let newReplacedByJokerKey = {...replacedByJokerKey}
            delete newReplacedByJokerKey[indexKey]
            setReplacedByJokerKey(newReplacedByJokerKey)

        }else{
            setReplacedByJokerKey({})
            let newReplacedByPermanentJoker = {...replacedByPermanentJoker}
            delete newReplacedByPermanentJoker[indexKey]
            setReplacedByPermanentJoker(newReplacedByPermanentJoker)
        }

    }


    function clickToAssignJoker(indexKey){
        let nbPermanentJoker = replacedByPermanentJoker[indexKey] ? replacedByPermanentJoker[indexKey].length : 0
        let nbKeyJoker = replacedByJokerKey[indexKey] ? replacedByJokerKey[indexKey].length : 0
        if((nbPermanentJoker + nbKeyJoker === 1) || nbPermanentJoker===0){
            assignJoker(indexKey,replacedByJokerKey[indexKey].length > 0 ? "key":"permanent")
        }else{
            setSelectJoker(indexKey)
        }
    }



    

    //const fetchScore = async () => {
    //    try{
    //        let response = await axios.get(process.env.REACT_APP_API_URL+"/get_individual_score?projectId="+idProjet,{headers: { Authorization: `Bearer ${jwtToken}` }})
            //setScore(response.data.score)
    //    }catch(e){
    //        console.log(e)
    //    }

    //}

    useEffect(()=>{
        if(address){
            fetchData();              

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[address])

   /*  useEffect(()=>{
        if(jwtToken){
            fetchScore();  
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[jwtToken]) */

    const checkWallet = async() => {
        const result = await axios.get(`${network.apiAddress}/accounts/${address}/nfts?size=10000&collections=${encodeURIComponent(collectionList.join(","))}`)
        let key = [...traitKey]
        
        let allJokerInWallet=[]

        //let contestNft=[]
        //let contestTrait=[]
        
        for(const nft of result.data){
            let functionJoker=undefined;
            
            //Check if can be used as a joker
            for(const item of joker){

                if(item.format === "nft" && item.nftId===nft.collection && parseInt(item.nonce)===nft.nonce){
                    functionJoker=item.function
                    let jokerInWallet={type:item.type,idCollection : item.nftId, nonce : item.nonce, function : functionJoker,replace: item.replace ? {traitName:item.replace.traitName,nftId:item.replace.gameCollection} : undefined}
                    allJokerInWallet.push(jokerInWallet)
                }else if(item.format==="trait" && item.nftId===nft.collection){
                    if(item.attributeOnChain){
                        const attributesUTF8 = base64ToUtf8(nft.attributes).split(";");
                        for (const attributes of attributesUTF8){
                            let trait = attributes.split(":")
                            let traitName = trait[0]
                            let traitValue = trait[1]
                            
                            if(item.traitName === traitName && item.traitValue === traitValue){
                                functionJoker=item.function
                                let jokerInWallet={type:item.type, idCollection : item.nftId, nonce : nft.nonce, function : functionJoker,replace: item.replace ? {traitName:item.replace.traitName,nftId:item.replace.gameCollection} : undefined}
                                allJokerInWallet.push(jokerInWallet)
                            }
                        }
                    }else{
                        for(const attribute of nft.metadata.attributes){
                            if(item.traitName === attribute.trait_type && item.traitValue === attribute.value){
                                functionJoker=item.function
                                let jokerInWallet={type:item.type, idCollection : item.nftId, nonce : item.nonce, function : functionJoker,replace: item.replace ? {traitName:item.replace.traitName,nftId:item.replace.gameCollection} : undefined}
                                allJokerInWallet.push(jokerInWallet)
                            }
                        }
                    }

                }
            }
            for(const item of key){

                if(item.type==="nft" && item.nftId===nft.collection && parseInt(item.nonce)===nft.nonce){

                    item.own = true
/*                     if(!alreadyClaimedNft.includes(item.id)){
                        contestNft.push(item.id)
                    } */
                }else if(item.type==="trait" && item.nftId===nft.collection){
                    if(item.attributeOnChain){

                        const attributesUTF8 = base64ToUtf8(nft.attributes).split(";");
                        for (const attributes of attributesUTF8){
                            let trait = attributes.split(":")
                            let traitName = trait[0]
                            let traitValue = trait[1]
                            console.log(item.traitName)
                            console.log(traitName)
                            console.log(item.traitValue)
                            console.log(traitValue)


                            if(item.traitName===traitName && item.traitValue === traitValue){
                                item.own = true
                                /* if(!alreadyClaimedTrait.includes(item.id)){
                                    contestTrait.push(item.id)
                                } */
                            }
                        }
                    }else{
                        for(const attribute of nft.metadata.attributes){
                            if(item.traitName===attribute.trait_type && item.traitValue === attribute.value){
                                item.own = true
                                if(!alreadyClaimedTrait.includes(item.id)){
                                    //contestTrait.push(item.id)
                                }
                            }
                        }
                    }

                }
            }
        }

        key = await jokerVerification(key,allJokerInWallet)

        setCheckedWallet(true)
        setTraitKey(key)
        /* if(contestTrait.length>0 || contestNft.length>0){
            contestValidation(contestTrait,contestNft)
        } */
    }

    /* const contestValidation = async (contestTrait,contestNft) =>{
        try{
            let response = await axios.post(`${process.env.REACT_APP_API_URL}/contest_validation`,
            {key:{nft:contestNft,trait:contestTrait}},
            {headers: { Authorization: `Bearer ${jwtToken}` }})

            let tableIdTrait=[]
            for(const item of response.data.trait){
                tableIdTrait.push(item.id)
            }
            

            setValidatedContestTrait(tableIdTrait)

            let tableIdNft=[]
            for(const item of response.data.nft){
                tableIdNft.push(item.id)
            }
            setValidatedContestNft(tableIdNft)

            //setAnimationScore(true)
            const interval = setInterval(() => {
                let newScore=score + response.data.totalPointNumber
                window.alert(newScore)
                setScore(prevCount => {
                  if (prevCount < newScore) {
                    return prevCount + 1;
                  } else {
                    clearInterval(interval);
                    //setAnimationScore(false)
                    return prevCount;
                  }
                });
            }, 100);
        }catch(e){
            console.log("Error contest validation")
        }
    } */

    const hours = Math.floor(seconds /  3600);
    const minutes = Math.floor((seconds % (3600))/60);
    const remainingSeconds = seconds % (60);


    //const daysContest = Math.floor(secondsContest / 86400)
    //const hoursContest = Math.floor((secondsContest % 86400) /  3600);
    //const minutesContest = Math.floor(((secondsContest % 86400) %  3600)/60);


    let traitComponents =
            traitKey.map((trait,index)=>{
                let toWrite = trait.type === "trait" ? `${trait.traitName.toUpperCase()} - ${trait.traitValue}` : `${trait.nftId}-${nonceToHexadecimal(parseInt(trait.nonce))}`
                if(trait.own || trait.replaced){
                    return <Trait key={index} orientation={"right"}>{toWrite}</Trait>
                }else if (checkedWallet){
                    if(Object.keys(replacedByJokerKey).includes(index.toString()) || Object.keys(replacedByPermanentJoker).includes(index.toString())){
                        return <Trait onClick={()=>clickToAssignJoker(index)} key={index} orientation={"center-joker"}>{toWrite}</Trait>
                    }else{
                        return <Trait marketplace={trait.type==="trait" ? `https://www.frameit.gg/marketplace/${trait.nftId}/items?&activeFilters=[{"trait":"${trait.traitName}","attribute":"${trait.traitValue}"}]`:`https://www.frameit.gg/marketplace/nft/${trait.nftId}-${nonceToHexadecimal(parseInt(trait.nonce))}`} key={index} orientation={"left"}>{toWrite}</Trait>
                    }
                }else{
                    return <Trait key={index} orientation={"center"}>{toWrite}</Trait>
                }
            })
       
    useEffect(()=>{
        if(traitKey.length > 0){
            let numberKey = 0
            for(const key of traitKey){
                if(key.own || key.replaced){
                    numberKey++
                }
            }
    
            setShowKeyValidation(numberKey === traitKey.length)

        }

    },[traitKey])

    async function claim(){
        try{
            if(forbiddenToClaim.includes(address)){
                setShowForbiddenPopup(true)
            }else{
                let response = await axios.post(`${process.env.REACT_APP_API_URL}/key_validation`,
                {projectId:idProjet},
                {headers: { Authorization: `Bearer ${jwtToken}` }})
                if(response.data.win){
                    let message = "";
                    for(const data of response.data.data){
                        const buffer = Buffer.from(data.message, "utf-8"); 
                        const hex = buffer.toString("hex");
                        const numBytes = hex.length / 2;
                        const numBytesHex = numBytes.toString(16).padStart(8, '0');
                        message+=`${numBytesHex}${hex}${data.signatureHex}`
                        
                    }
                    getRewardsTransaction(message)
                    
                }
            }
            
        }catch{
            console.log("Error")
        }
        

    }
    return(
        <>
        {closeGame && <Card background={projectName ? `${process.env.REACT_APP_API_URL}/${projectName}/background.png` : undefined}><div className={styles.game}><div className={styles.closeGame}>All prizes have been won. Thanks for your participation in this beta version</div></div></Card>}

        {!closeGame && <Card isMobile={showMobile} background={projectName ? `${process.env.REACT_APP_API_URL}/${projectName}/background.png` : undefined}>

            {waitingTransaction && <SignTransactionAlert onClose={()=>{setWaitingTransaction(false)}}/>}
            {showForbiddenPopup && <ForbiddenPopup onClose={()=>{setShowForbiddenPopup(false)}}/>}

            {selectJoker !== undefined && <PopupSelectJoker onClose={()=>setSelectJoker(undefined)} keyIndex={selectJoker} action={assignJoker} randomJoker={replacedByJokerKey.length!==0 ?replacedByJokerKey[selectJoker]:[{nftId:"jknlp",nonce:"kjl"}]} permanentJoker={replacedByPermanentJoker.length!==0 ?replacedByPermanentJoker[selectJoker]:[]}/>}
            <div className={styles.game}>

                <img src={networkImg} alt="network"/>
                {(!showStatTool && !showPremiumTool) && <>
                    <img alt="" className={styles.arrow} src={arrowImg} onClick={onClose}/>

                    <div className={styles.header}>
 {/*                        <div className={styles.buttonStats}>
                            <ButtonDesigned className={styles.contestInfo}>
                                <table>
                                    <tr><td>Score<span className={styles.spanHide}> contest</span></td><td><span className={animationScore && styles.scoreIncreasing}>{score}</span> point{score !== 0 && "s"}</td></tr>
                                    <tr><td>Time<span className={styles.spanHide}>  contest</span></td><td>{seconds>=0 && <><span>{daysContest}</span>D <span>{hoursContest}</span>H <span>{minutesContest}</span>M</>}</td></tr>
                                </table>
                            </ButtonDesigned>
                        </div> */}
                        <div>
                            <h2>The Ants Game - Beta</h2>
                            {<ButtonDesigned onClick={!showKeyValidation ? checkWallet : claim} className={styles.buttonCheckWallet + " " + (showKeyValidation && styles.buttonClaim) }>{!showKeyValidation ? 'Check my wallet':'Claim'}</ButtonDesigned> }
                        </div>
                        <div><img alt="" src={`${process.env.REACT_APP_API_URL}/${projectName}/logo.png`}/></div> 
                    </div>

                    <div className={styles.traitGallery}>
                        {traitKey.map(trait => 
                            <div className={styles.frameKey} title={`${trait.traitName} ${trait.traitValue}`}>
                                <img alt="" className={styles.frameImg} src={frame}/>
                                <GameImage  src={`${process.env.REACT_APP_API_URL+trait.urlImage}`} alt={trait.type!=="nft" ? `${trait.traitName} ${trait.traitValue}` : `${trait.nftId}-${nonceToHexadecimal(parseInt(trait.nonce))}`}/>
                                {/* <div className={
                                        styles.score + " " +
                                        (((trait.type==="trait" && validatedContestTrait.includes(trait.id)) || (trait.nft==="nft" && validatedContestNft.includes(trait.id))) ? styles.greenAnimation : "")
                                    }>
                                    {trait.pointNumber}
                                </div> */}
                            </div>
                        )}
                    </div>
                    <div className={styles.bottom}>
                        
                        <div className={styles.cardTrait}>
                            
                            {traitComponents}
                            
                        </div>
                        <div>
                            <ButtonDesigned onClick={()=>setShowStatTool(true)}>Game tools</ButtonDesigned>
{/*                             <div className={styles.frameKey} onClick={showTresory ? ()=>setShowTresory(false) : ()=>setShowTresory(true)}>
 */}{/*                                 <img className={styles.frameImg} src={frame}/>
 */}                                <img alt="" className={styles.gameImg} src={tresory2}/>
                                    <div className={styles.infoTresory}>
{/*                                         <div className={styles.amountTresory}>
                                            <div>Contest</div>
                                            <div><span className={styles.spanHide}>Value </span>{totalApproximatePriceContest} EGLD</div>
                                        </div> */}
                                        
                                        {
                                            !showBank ? 
                                                <div onClick={()=>setShowBank(true)} className={styles.amountTresory}>
                                                    <div>Full Key</div>
                                                    <div><span className={styles.spanHide}>Value </span>{totalApproximatePriceGame} EGLD</div>
                                                </div>
                                            :
                                                <div onClick={()=>setShowBank(false)} className={styles.amountTresory}>
                                                    {
                                                        bank.nft.map((toWin)=>
                                                            <div>{`${toWin.quantity} NFT ${toWin.collectionId}-${nonceToHexadecimal(toWin.nonce)}`}</div>
                                                        )
                                                    }{
                                                        bank.token.map((toWin)=>
                                                            <>
                                                            <div>{`${Math.floor(toWin.quantity*100)/100} token ${toWin.tokenId}`}</div>
                                                            </>
                                                        )
                                                    }{
                                                        <div>{bank.egld !== 0 && `${Math.floor(bank.egld*100)/100} EGLD`}</div>
                                                    }
                                                </div>
                                        }
                                    </div>
{/*                                 <img className={styles.gameImg} src={tresory}/>
 */}{/*                                 <div className={styles.title}>Pool</div>
 */}                                {/* <Transition nodeRef={nodeRef} in={showTresory} timeout={duration}>
                                    {state => (
                                        <div className={styles.popupInfoContainerLeft} onClick={(event)=>event.stopPropagation()}>
                                            <div className={styles.popupInfo} ref={nodeRef} style={{
                                                ...defaultStyleLeft,
                                                ...transitionStylesLeft[state]
                                                }}>
                                                {
                                                    bank.nft.map((toWin)=>
                                                        <div>{`${toWin.quantity} NFT ${toWin.collectionId}-${nonceToHexadecimal(toWin.nonce)}`}</div>
                                                    )
                                                }{
                                                    bank.token.map((toWin)=>
                                                        <>
                                                        <div>{`${Math.floor(toWin.quantity*100)/100} token ${toWin.tokenId}`}</div>
                                                        </>
                                                    )
                                                }{
                                                    <div>{bank.egld !== 0 && `${Math.floor(bank.egld*100)/100} EGLD`}</div>
                                                }
                                            </div>
                                        </div>
                                    )}
                                </Transition> */}
                            {/* </div> */}
                        </div>
                        <div>
                            <ButtonDesigned onClick={()=>setShowPremiumTool(true)}>Game rules</ButtonDesigned>
                            <div className={styles.frameKey} onClick={showJokerInfo ? ()=>setShowJokerInfo(false) : ()=>setShowJokerInfo(true)}>
                            {jokerKey && <>
                                <img alt="" className={styles.frameImg + " "+styles.joker} src={frame}/>
                                <GameImage src={jokerKey !== undefined && `${process.env.REACT_APP_API_URL+jokerKey.url}`} alt={jokerKey !== undefined && (jokerKey.format === "nft" ? `${jokerKey.nftId}-${nonceToHexadecimal(parseInt(jokerKey.nonce))}` : `${jokerKey.traitName} ${jokerKey.traitValue}`)}/>
                                <div className={styles.titleLeft}>Joker</div>
                                <Transition nodeRef={nodeRef2} in={showJokerInfo} timeout={duration}>
                                    {state => (
                                        <div className={styles.popupInfoContainerRight} onClick={(event)=>event.stopPropagation()}>
                                            <div className={styles.popupInfo} ref={nodeRef2} style={{
                                                ...defaultStyle,
                                                ...transitionStyles[state]
                                                }}>
                                                This attribute automatically validates a {jokerKey !== undefined && (jokerKey.function==="missing attribute" ? "missing attribute" : `${jokerKey.replace.traitName} trait`)}
                                            </div>
                                        </div>
                                    )}
                                </Transition>
                            </>}
                            </div>
                        </div>
                        
                    </div>
                </>}
                {
                    showStatTool && <GameTool onClose={()=>setShowStatTool(false)} idProject={idProjet}/>
                }
                {
                    showPremiumTool && <GameToolPremium onClose={()=>setShowPremiumTool(false)} redirectMint={onClose}/>
                }
                
            </div>
            {!showStatTool && !showPremiumTool && <div className={styles.timer + ` ${isMobile && styles.timerMobile}`}>{seconds>=0 && <><span>{hours}</span>h <span>{minutes}</span>m <span>{remainingSeconds}</span>s</>}</div>}
        </Card>}
        </>
    )
}

export default Game;