import { useState } from "react"
import styles from "./EditProfile.module.scss"
import Input from "../Input"
import {useGetAccountInfo} from '@multiversx/sdk-dapp/hooks';
import axios from "axios"




const EditProfile = (props) => {
    const [user,setUser]=useState(props.user)
    const [discord,setDiscord]=useState(props.discord)
    const [mail,setMail]=useState(props.mail)
    const [facebook,setFacebook]=useState(props.facebook)
    const [twitter,setTwitter]=useState(props.twitter)

    const { address } = useGetAccountInfo();

    const insertNewData=async()=>{
        try{
            let data={address:address,user:user,facebook:facebook,twitter:twitter,discord:discord,mail:mail}
            var form_data = new FormData();

            for ( var key in data ) {
                form_data.append(key, data[key]);
            }
            let response = await axios.post(`${process.env.REACT_APP_API_LINK}/set_user.php`,form_data)
            if(response.data==="ok"){
                props.setUser(user)
                props.setFacebook(facebook)
                props.setTwitter(twitter)
                props.setDiscord(discord)
                props.setMail(mail)
                props.onClose()
                
            }
        }catch(error){
            console.log(error)
        }

    }

    const triggerOnKeyPress = (input,type) =>{
        switch (type) {
            case 'user':
                setUser(input.target.value)
                break
            case 'facebook':
                setFacebook(input.target.value)
                break
            case 'twitter':
                setTwitter(input.target.value)
                break
            case 'discord':
                setDiscord(input.target.value)
                break
            case 'mail':
                setMail(input.target.value)
                break
            default:
                break
        }

    }
    
    return(
        <>
        <div className={styles.edit}>
            <Input onChange={(input) => triggerOnKeyPress(input,"user")} value={user} label="User Name"/>
            <Input onChange={(input) => triggerOnKeyPress(input,"discord")} value={discord} label="Discord URL"/>
            <Input onChange={(input) => triggerOnKeyPress(input,"twitter")} value={twitter} label="Twitter URL"/>
            <Input onChange={(input) => triggerOnKeyPress(input,"facebook")} value={facebook} label="Facebook URL"/>
            <Input onChange={(input) => triggerOnKeyPress(input,"mail")} value={mail} label="Notification Email"/>
        </div>
        <div className={styles.button}>
            <button onClick={insertNewData}>Save Changes</button>
        </div>
        </>
    )
}

export default EditProfile;