import {useState} from "react"
import styles from "./Command.module.scss"
import Button from "../ButtonDesignedVar"
import SignTransactionAlert from "../SignTransactionAlert";
import {utils} from 'ethers'
import {refreshAccount} from '@multiversx/sdk-dapp/utils/account';
import {useSignTransactions} from '@multiversx/sdk-dapp/hooks/transactions';
import {sendTransactions} from '@multiversx/sdk-dapp/services';

const Command = (props) => {
    useSignTransactions();
    const [, setSessionId] = useState(null);
    const [nbToMint,setNbToMint] = useState(1)
    const [waitingTransaction,setWaitingTransaction]=useState(false);
    const decreaseNb=()=>{
        if(nbToMint - 1 > 0){
            setNbToMint(nbToMint-1)
        }
    }

    const increaseNb=()=>{
        if(nbToMint + 1 <= props.maxToMint){
            setNbToMint(nbToMint+1)
        }
    }

    const mint = async() => {
        if(!waitingTransaction){
            const contractAddress = process.env.REACT_APP_SMART_CONTRACT
            let totalPrice = Math.round(props.price*nbToMint*100)/100
            let totalPriceConvert = utils.parseEther(totalPrice.toString())
    
            let nbToBuy = nbToMint.toString(16);
            if (nbToBuy.length % 2 !== 0 ) {
                nbToBuy = "0"+nbToBuy;
            }
            const mintTransaction = {
                value: totalPriceConvert.toString(),
                data: 'mint@'+nbToBuy+"@0"+props.number,
                receiver: contractAddress,
                gasLimit: 50_000_000,
            };
            await refreshAccount();
            const { sessionId,  } = await sendTransactions({
                transactions: mintTransaction,
                transactionsDisplayInfo:{
                    processingMessage: 'Processing transaction',
                    errorMessage: 'An error has occured during transaction',
                    successMessage: 'Success'
                },
                redirectAfterSign: false
            });
            if (sessionId != null){
                setSessionId(sessionId);
                setWaitingTransaction(true);
            }
        }
    }
/*     const transactionStatus = transactionServices.useTrackTransactionStatus({
        transactionId: sessionId,
        onFail:()=>{setWaitingTransaction(false)},
        onCancelled:()=>{setWaitingTransaction(false)},
      }); */

    return(
        <>
            <div className="bg" onClick={props.onClose}></div>
            {waitingTransaction && <SignTransactionAlert/>}
            <div className={styles.command}>
                
                <div>
                    {<div className={!props.soldOut ? styles.title : (styles.title + " " +styles.opacity)}><h2>{props.title}</h2></div>}
                    <div className={styles.content}>
                        {!props.soldOut ? (
                            <>
                                <div>Nb to mint</div>
                                <div><div onClick={decreaseNb}><span >-</span></div><div><span>{nbToMint}</span></div><div onClick={increaseNb}><span >+</span></div></div>
                                <div className={styles.subContent}><div>Number Ants Club : {props.nbAntsClub * nbToMint}</div><div>Number Ants Game : {props.nbAntsGame * nbToMint}</div></div>
                                <div className={styles.subContent}><div>price : <span>{Math.round(props.price*nbToMint*100)/100}</span> Egld</div><Button onClick={mint}>Mint</Button></div>
                            </>
                        ):(
                            <>
                                <div className={styles.soldOut}>Sold Out</div>
                                
                            </>
                        )}

                    </div>
                </div>
                <div>{props.statut === "Sale" ? props.statut : ("Presale 2")}</div>
            </div>
        </>
    )
}

export default Command;