import styles from "./ContestRule.module.scss"
import ButtonDesigned from "../ButtonDesigned"
const ContestRule = ({onClose,openListTrait,openTresory,nameCollection,keyTime,keyNumber}) => {

    const redirectTo = (to) => {
        switch(to){
            case 'listTrait':
                onClose()
                openListTrait()
                break
            case 'wins':
                onClose()
                openTresory()
                break
            default:
                break
        }
    }

    return(
        <div className={styles.contestRule}>
            <h2>Contest Rule</h2>
            <p>Welcome to Ants Game Contest, the game where you can give all your NFTs a new purpose and win exclusive rewards!</p>

            <h3>Objective of the game :</h3>

            <p>The goal of the game is to create a smart and strategic collection of NFTs from the {nameCollection}{" "}
             collection so that you can score as many points as possible during the contest time.</p>

            <h3>How to play :</h3>
            <p>A set of 5 features is drawn every {Math.round(keyTime/60)} minutes. Your goal is to regularly check your NFTs for one or more of the required traits before the series changes. Each trait scores a greater or lesser number of points depending on how rare it is.
            If you manage to have all of the requested traits in a series, you earn points and a point bonus is awarded to you. 
            A new series is then automatically generated, even if the regeneration counter is not finished.You can find the list of features <span className={styles.link} onClick={()=>redirectTo("listTrait")}>HERE</span>.</p>

            <h3>The jokers :</h3>
            <p>A {keyNumber + 1}{(keyNumber+1)>3 ? "th" : ((keyNumber+1)===3 ? "rd" : ((keyNumber+1)===2 ? "nd" : "st"))} trait is also drawn, this trait is a joker that allows you to validate an option. 
            In the case of several possibilities, take the time to think carefully about the chosen option because once used, this choice will not be 
            modifiable for this series. You can find the list of joker features HERE.</p>

            <h3>The tool :</h3>
            <p>A tool is available to help you in the game. You will find different information, such as the amount of points per trait, their rarity level, 
            a direct link to the secondary market and many other useful information. 
            A premium tool is also available to create collection strategies, analyse your wallet to inform you of unnecessary NFTs and many other 
            options not available on the standard tool. To access this premium tool, you must have an NFT Ants Club.</p>

            <h3>Ranking :</h3>
            <p>A ranking is established as players score points. At the end of the contest, the players who have scored the most points win money according to their rank. You can find the list of winnings according to the ranking  <span className={styles.link} onClick={()=>redirectTo("wins")}>HERE</span></p>

            <h3>What is prohibited :</h3>
            <p>The use of bots to automate verification.
            Bots are not welcome in our game. We don't want our players to feel like they are competing with machines rather than with other human players.
            Grouping players together to form a team.
            Similarly, we do not allow multiple players to group together by sending their NFTs to a portfolio to create a group collection.
            We want each player to have the opportunity to play fairly and individually.
            Peer-to-peer NFT trading.
            What you need to understand is that in order to be able to distribute winnings to you, the collections need the royalties from the secondary market.
            Therefore, only NFTs from the market place are eligible for the game.
            The control of these rules is done automatically or after the end of the contest time on the winners wallet.</p>

            <p>Failure to comply with the rules will result in severe consequences, including banning the wallet address, exclusion from the leaderboard and even a ban on using NFTs in the game.</p>

            <p>We know this sounds harsh, but we want to make sure that all players have a fair chance to win. So let's play together in an honest and fun way!</p>

            <p>So get ready to explore your NFTs, use your strategy and score points to win exclusive rewards.</p>

            <p>Good luck to all Ants Game Contest players!</p>

            <ButtonDesigned className={styles.button} onClick={onClose}>Back into the game</ButtonDesigned>

        </div>
    )
}

export default ContestRule;